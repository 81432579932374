import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../css/common.css';

const CinemaHallObservationForm = () => {
  const [observation, setObservation] = useState({
    observerName: '',
    observationDate: '',
    participantId:'',
    cinemaHallType: '',
    cinemaHallAddress: '',
    buildingAge: '',
    floorLevel: '',
    otherFloorLevel: '', // Added state for other floor level
    totalArea: '',
    seatingCapacity: '',
    seatingType: '',
    otherSeatingType: '', // Added state for other seating type
    floorType: '',
    occupantsDensity: '',
    cookingFacilities: '',
    smokingAreas: '',
    furnishingsDecor: '',
    otherFurnishingsDecor: '', // Added state for other furnishings and decor
    housekeepingFrequency: '',
    cleaningMaterials: '',
    otherCleaningMaterials: '', // Added state for other cleaning materials
    scentsUsed: '',
    otherScentsUsed: '', // Added state for other scents used
    scentSprayingFrequency: '',
    otherScentSprayingFrequency: '', // Added state for other scent spraying frequency
    hvacMaintenanceFrequency: '',
    numOfVents: '',
    exhaustFanInletVelocity: '',
    exhaustFanOutletVelocity: '',
    pestControlFrequency: '',
    otherPestControlFrequency: '', // Added state for other pest control frequency
    airFilterReplacementFrequency: '',
    otherAirFilterReplacementFrequency: '', // Added state for other air filter replacement frequency
    airPurifiers: '',
    locationId: '',
    remarks: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObservation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOtherFloorLevelChange = (e) => {
    const { name, value } = e.target;
    setObservation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOtherSeatingTypeChange = (e) => {
    const { name, value } = e.target;
    setObservation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request with observation data
      // const response = await axios.post('http://localhost:8081/api/cinema-hall-observations/add', observation);
      const response = await axios.post('http://89.116.20.162:8081/api/cinema-hall-observations/add', observation);

      console.log(response.data);
      // Reset observation state after successful submission
      setObservation({
        observerName: '',
        observationDate: '',
        participantId:'',
        cinemaHallType: '',
        cinemaHallAddress: '',
        buildingAge: '',
        floorLevel: '',
        otherFloorLevel: '',
        totalArea: '',
        seatingCapacity: '',
        seatingType: '',
        otherSeatingType: '',
        floorType: '',
        occupantsDensity: '',
        cookingFacilities: '',
        smokingAreas: '',
        furnishingsDecor: '',
        otherFurnishingsDecor: '',
        housekeepingFrequency: '',
        cleaningMaterials: '',
        otherCleaningMaterials: '',
        scentsUsed: '',
        otherScentsUsed: '',
        scentSprayingFrequency: '',
        otherScentSprayingFrequency: '',
        hvacMaintenanceFrequency: '',
        numOfVents: '',
        exhaustFanInletVelocity: '',
        exhaustFanOutletVelocity: '',
        pestControlFrequency: '',
        otherPestControlFrequency: '',
        airFilterReplacementFrequency: '',
        otherAirFilterReplacementFrequency: '',
        airPurifiers: '',
        locationId: '',
        remarks: ''
      });
      alert('Observation submitted successfully!');
    } catch (error) {
      console.error('Error submitting observation:', error);
      alert('An error occurred while submitting the observation. Please try again.');
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <center><h1>Cinema Hall</h1></center>
        <label>
          Location ID:
          <input
            type="text"
            name="locationId"
            value={observation.locationId}
            onChange={handleChange}
            required
          />
        </label>
        {/* <label>
          Location ID:
          <input
            type="text"
            name="participantId"
            value={observation.participantId}
            onChange={handleChange}
            required
          />
        </label> */}
        <label>
          Name of Observer:
          <input
            type="text"
            name="observerName"
            value={observation.observerName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Observation Date:
          <input
            type="date"
            name="observationDate"
            value={observation.observationDate}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Type of Cinema Hall:
          <select
            name="cinemaHallType"
            value={observation.cinemaHallType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Single screen">Single screen</option>
            <option value="Standalone Multiplex">Standalone Multiplex</option>
            <option value="Multiplex in a mall">Multiplex in a mall</option>
          </select>
        </label>
        <label>
          Address of the Cinema Hall:
          <input
            type="text"
            name="cinemaHallAddress"
            value={observation.cinemaHallAddress}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Building Age in Years:
          <select
            name="buildingAge"
            value={observation.buildingAge}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<5">&lt;5</option>
            <option value="5 to 10">5 to 10</option>
            <option value="10 to 20">10 to 20</option>
            <option value="20 to 30">20 to 30</option>
            <option value=">30">&gt;30</option>
          </select>
        </label>
        <label>
          Location of the hall (Floor level):
          <select
            name="floorLevel"
            value={observation.floorLevel}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="Other">Other</option> {/* Added "Other" option */}
          </select>
          {/* Render text input for "Other" option */}
          {observation.floorLevel === "Other" && (
            <input
              type="text"
              name="otherFloorLevel"
              value={observation.otherFloorLevel}
              onChange={handleOtherFloorLevelChange}
              placeholder="Please specify"
              required
            />
          )}
        </label>
        <label>
          Total Area of Hall:
          <select
            name="totalArea"
            value={observation.totalArea}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<50m2">&lt;50m2</option>
            <option value="100-200m2">100-200m2</option>
            <option value="200-400m2">200-400m2</option>
            <option value=">400m2">&gt;400m2</option>
          </select>
        </label>
        <label>
          Seating Capacity:
          <select
            name="seatingCapacity"
            value={observation.seatingCapacity}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<100">&lt;100</option>
            <option value="100-300">100-300</option>
            <option value=">300">&gt;300</option>
          </select>
        </label>
       
        <label>
  Type of seating:
  <select
    name="seatingType"
    value={observation.seatingType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Standard seats">Standard seats</option>
    <option value="Recliner seats">Recliner seats</option>
    <option value="Other">Other</option> {/* Added "Other" option */}
  </select>
  {/* Render text input for "Other" option */}
  {observation.seatingType === "Other" && (
    <input
      type="text"
      name="otherSeatingType"
      value={observation.otherSeatingType}
      onChange={handleOtherSeatingTypeChange}
      placeholder="Please specify"
      required
    />
  )}
</label>
        <label>
          Density of Occupants:
          <select
            name="occupantsDensity"
            value={observation.occupantsDensity}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Sparse">Sparse</option>
            <option value="Moderate">Moderate</option>
            <option value="Crowded">Crowded</option>
          </select>
        </label>
        <label>
          Cooking Facilities within the Cinema Hall:
          <select
            name="cookingFacilities"
            value={observation.cookingFacilities}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <label>
          Presence of Smoking or Vaping Areas:
          <select
            name="smokingAreas"
            value={observation.smokingAreas}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <label>
  What types of furnishings and decor are present within the cinema hall?
  <select
    name="furnishingsDecor"
    value={observation.furnishingsDecor}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Upholstered furniture">Upholstered furniture</option>
    <option value="Carpeting or rugs">Carpeting or rugs</option>
    <option value="Curtains or drapes">Curtains or drapes</option>
    <option value="Wall coverings">Wall coverings (e.g., wallpaper)</option>
    <option value="Other">Other</option>
  </select>
  {/* Render text input for "Other" option */}
  {observation.furnishingsDecor === "Other" && (
    <input
      type="text"
      name="otherFurnishingsDecor"
      value={observation.otherFurnishingsDecor}
      onChange={handleChange}
      placeholder="Please specify"
      required
    />
  )}
</label>

        <label>
          Frequency of Housekeeping Activities per Day:
          <select
            name="housekeepingFrequency"
            value={observation.housekeepingFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1-2">1-2</option>
            <option value="2-4">2-4</option>
            <option value="4-6">4-6</option>
            <option value="6-8">6-8</option>
            <option value=">10">&gt;10</option>
          </select>
        </label>
        <label>
  Chemical-Based Cleaning Materials Used:
  <select
    name="cleaningMaterials"
    value={observation.cleaningMaterials}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Disinfectants">Disinfectants</option>
    <option value="Floor cleaners">Floor cleaners</option>
    <option value="Others">Others</option>
  </select>
  {/* Render text input for "Others" option */}
  {observation.cleaningMaterials === "Others" && (
    <input
      type="text"
      name="otherCleaningMaterials"
      value={observation.otherCleaningMaterials}
      onChange={handleChange}
      placeholder="Please specify"
      required
    />
  )}
</label>

<label>
  Scents Used in the Cinema Hall:
  <select
    name="scentsUsed"
    value={observation.scentsUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Perfumes">Perfumes</option>
    <option value="Room fresheners">Room fresheners</option>
    <option value="Others">Others</option>
  </select>
  {/* Render text input for "Others" option */}
  {observation.scentsUsed === "Others" && (
    <input
      type="text"
      name="otherScentsUsed"
      value={observation.otherScentsUsed}
      onChange={handleChange}
      placeholder="Please specify"
      required
    />
  )}
</label>
<label>
  Frequency of Spraying the Scents:
  <select
    name="scentSprayingFrequency"
    value={observation.scentSprayingFrequency}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<30 minutes">&lt;30 minutes</option>
    <option value="1-2 hours">1-2 hours</option>
    <option value=">2 hours">&gt;2 hours</option>
    <option value="Others">Others</option>
  </select>
  {/* Render text input for "Others" option */}
  {observation.scentSprayingFrequency === "Others" && (
    <input
      type="text"
      name="otherScentSprayingFrequency"
      value={observation.otherScentSprayingFrequency}
      onChange={handleChange}
      placeholder="Please specify"
      required
    />
  )}
</label>

        <label>
          How Frequently is the HVAC System Inspected and Maintained:
          <select
            name="hvacMaintenanceFrequency"
            value={observation.hvacMaintenanceFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Annually">Annually</option>
            <option value="Irregularly">Irregularly</option>
          </select>
        </label>
        <label>
          Number of Vents:
          <select
            name="numOfVents"
            value={observation.numOfVents}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="10-20">10-20</option>
            <option value="20-30">20-30</option>
            <option value="30-40">30-40</option>
            <option value="40-50">40-50</option>
            <option value=">50">&gt;50</option>
          </select>
        </label>
        <label>
          Exhaust Fan Air Velocities Inlet:
          <input
            type="text"
            name="exhaustFanInletVelocity"
            value={observation.exhaustFanInletVelocity}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Exhaust Fan Air Velocities Outlet:
          <input
            type="text"
            name="exhaustFanOutletVelocity"
            value={observation.exhaustFanOutletVelocity}
            onChange={handleChange}
            required
          />
        </label>
        <label>
  Pest Control Frequency:
  <select
    name="pestControlFrequency"
    value={observation.pestControlFrequency}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Recently">Recently</option>
    <option value="<1 year">&lt;1 year</option>
    <option value="1-2 years">1-2 years</option>
    <option value="Others">Others</option>
  </select>
  {/* Render text input for "Others" option */}
  {observation.pestControlFrequency === "Others" && (
    <input
      type="text"
      name="otherPestControlFrequency"
      value={observation.otherPestControlFrequency}
      onChange={handleChange}
      placeholder="Please specify"
      required
    />
  )}
</label>

<label>
  How Often are the Air Filters in the HVAC System Replaced:
  <select
    name="airFilterReplacementFrequency"
    value={observation.airFilterReplacementFrequency}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Every month">Every month</option>
    <option value="Every three months">Every three months</option>
    <option value="Every six months">Every six months</option>
    <option value="Annually">Annually</option>
    <option value="Other">Other</option>
  </select>
  {/* Render text input for "Other" option */}
  {observation.airFilterReplacementFrequency === "Other" && (
    <input
      type="text"
      name="otherAirFilterReplacementFrequency"
      value={observation.otherAirFilterReplacementFrequency}
      onChange={handleChange}
      placeholder="Please specify"
      required
    />
  )}
</label>

        <label>
          Any Type of Air Purifiers or Filters Used:
          <select
            name="airPurifiers"
            value={observation.airPurifiers}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
       
        
        <label>
        Remarks:
          <input
            type="text"
            name="remarks"
            value={observation.remarks}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default CinemaHallObservationForm;
