// // src/components/Dashboard.js
// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import '../components/css/Dashboard.css'; // Create CSS file for styling

// const data = [
//   { name: 'Jan', value: 400 },
//   { name: 'Feb', value: 300 },
//   { name: 'Mar', value: 200 },
//   { name: 'Apr', value: 278 },
//   { name: 'May', value: 189 },
// ];

// const Dashboard = () => {
//   return (
//     <div className="dashboard">
//       {/* <div className="header">
//         <h1>Indoor Air Quality</h1>
//         <input type="text" placeholder="Search city here..." />
//       </div> */}
//       <div className="content">
//         <div className="metrics-cards">
//           <div className="card">Volatile Organic Carbon: 1 ppm</div>
//           <div className="card">Carbon Dioxide: 3000 ppm</div>
//           <div className="card">Carbon Monoxide: 28 ppm</div>
//           <div className="card">Relative Humidity: 79%</div>
//           <div className="card">Wind Velocity: 1</div>
//           <div className="card">Pressure: 1 bar</div>
//         </div>
//         <div className="charts">
//           <LineChart width={500} height={300} data={data}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="name" />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
//           </LineChart>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// current 17/07
// import React from 'react';
// import GrimmsData from './samplinggraphs/GrimmsData';
// import TestoData from './samplinggraphs/TestoData';
// import TigerxtData from './samplinggraphs/TigerxtData';
// import '../components/css/Dashboard.css'; // Assuming you have a Dashboard.css file for additional styling
// import GraphLocationsdata from '../healthgraphs/GraphLocationsdata';

// const Dashboard = () => {
//   return (
//     <div className="container-fluid">
//         {/* <GraphLocationsdata></GraphLocationsdata>  */}
//       <div className="row justify-content-center">
//         <div className="col-lg-4 col-md-6 mb-4">
//           <div className="card">
//             {/* <GraphLocationsdata></GraphLocationsdata> */}
//             <div className="card-body">
//               {/* <h5 className="card-title">Grimms Data</h5> */}
//               <TigerxtData />

//             </div>
//           </div>
//         </div>
//         <div className="col-lg-4 col-md-6 mb-4">
//           <div className="card">
//             <div className="card-body">
//               {/* <h5 className="card-title">Testo Data</h5> */}
//               <TestoData />
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-4 col-md-6 mb-4">
//           <div className="card">
//             <div className="card-body">
//               <h5 className="card-title">Tigerxt Data</h5>
//               <GrimmsData />

//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// import React, { useState, useEffect } from 'react';
// import GrimmsData from './samplinggraphs/GrimmsData';
// import TestoData from './samplinggraphs/TestoData';
// import TigerxtData from './samplinggraphs/TigerxtData';
// import Sidebar from './Sidebar';
// import '../components/css/Dashboard.css'; // Assuming you have a Dashboard.css file for additional styling

// const Dashboard = () => {
//   const [selectedLocation, setSelectedLocation] = useState('');

//   const handleFilterChange = (filterValue) => {
//     setSelectedLocation(filterValue);
//   };

//   return (
//     <div className="dashboard-container">
//       <div className="row">
//         <div className="col-md-3">
//           <Sidebar handleFilterChange={handleFilterChange} />
//         </div>
//         <div className="col-md-9">
//           <div className="chart-container">
//             <div className="chart">
//               {/* <h5>Grimms Data</h5> */}
//               <TigerxtData selectedLocation={selectedLocation} />

//             </div>
//             <div className="chart">
//               {/* <h5>Testo Data</h5> */}
//               <TestoData selectedLocation={selectedLocation} />
//             </div>
//             <div className="chart">
//               {/* <h5>Tigerxt Data</h5> */}
//               <GrimmsData selectedLocation={selectedLocation} />

//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// import React from "react";
// import GrimmsData from "./samplinggraphs/GrimmsData";
// import TestoData from "./samplinggraphs/TestoData";
// import TigerxtData from "./samplinggraphs/TigerxtData";
// import GraphLocationsdata from "../healthgraphs/GraphLocationsdata";
// import "../components/css/Dashboard.css"; // Assuming you have a Dashboard.css file for additional styling

// const Dashboard = () => {
//   return (
//     <>
//       <div>
//         <div className="location-graph">
//           <div className="card-body">
//             <GraphLocationsdata />
//           </div>
//         </div>
//       </div>
//       <div className="container-fluid">
//         <div className="row justify-content-center">
//           <div className="col-lg-4 col-md-6 mb-4">
//             <div className="card">
//               <div className="card-body">
//                 <GrimmsData />
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-4 col-md-6 mb-4">
//             <div className="card">
//               <div className="card-body">
//                 <TestoData />
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-4 col-md-6 mb-4">
//             <div className="card">
//               <div className="card-body">
//                 <TigerxtData />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Dashboard;
//---------------------------------------------6 aug------------------------------------------------------------
// import React from "react";
// import GrimmsData from "./samplinggraphs/GrimmsData";
// import TestoData from "./samplinggraphs/TestoData";
// import TigerxtData from "./samplinggraphs/TigerxtData";
// import GraphLocationsdata from "../healthgraphs/GraphLocationsdata";
// import ParticipantAgeRangeChart from "../healthgraphs/ParticipantAgeRangeChart";
// import GenderCountsChart from "../healthgraphs/GenderCountsChart";
// import LungOscillometryChart from "../healthgraphs/LungOscillometryChart";
// import "../components/css/Dashboard.css"; // Assuming you have a Dashboard.css file for additional styling

// const Dashboard = () => {
//   return (
//     <div className="dashboard-container">
//       <div className="graph-locations">
//         <div className="card">
//           <div className="card-body">
//             <GraphLocationsdata />
//           </div>
//           <div>
//             <ParticipantAgeRangeChart />
//           </div>
//           <div>
//             <GenderCountsChart />
//           </div>

//           <div>
//             <LungOscillometryChart />
//           </div>
          
//         </div>
//       </div>
//       <div className="data-container">
//         <div className="cardd">
//           <div className="card-bodyy">
//             <GrimmsData />
//           </div>
//         </div>
//         <div className="cardd">
//           <div className="card-bodyy">
//             <TestoData />
//           </div>
//         </div>
//         <div className="cardd">
//           <div className="card-bodyy">
//             <TigerxtData />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaHome, FaChartLine, FaBars, FaHandHoldingMedical } from 'react-icons/fa';
import GrimmsData from './samplinggraphs/GrimmsData';
import TestoData from './samplinggraphs/TestoData';
import TigerxtData from './samplinggraphs/TigerxtData';
import GraphLocationsdata from '../healthgraphs/GraphLocationsdata';
import ParticipantAgeRangeChart from '../healthgraphs/ParticipantAgeRangeChart';
import GenderCountsChart from '../healthgraphs/GenderCountsChart';
import LungOscillometryChart from '../healthgraphs/LungOscillometryChart';
import '../components/css/Dashboard.css';
import '../components/css/Sidebar.css';

const Dashboard = () => {
  const [openHealth, setOpenHealth] = useState(false);
  const [openIAQ, setOpenIAQ] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    fetchLocationIds();
  }, []);

  const fetchLocationIds = async () => {
    try {
      const response = await axios.get('http://89.116.20.162:8081/api/tigerxt/locationIds');
      setLocationIds(response.data);
    } catch (error) {
      console.error('Error fetching location IDs:', error);
    }
  };

  const toggleHealth = () => {
    setOpenHealth(!openHealth);
    setOpenIAQ(false);
    setOpenFilters(false);
  };

  const toggleIAQ = () => {
    setOpenIAQ(!openIAQ);
    setOpenHealth(false);
    setOpenFilters(false);
  };

  const toggleFilters = () => {
    setOpenFilters(!openFilters);
    setOpenHealth(false);
    setOpenIAQ(false);
  };

  const handleFilterChange = (filterType, value) => {
    setFilter({ ...filter, [filterType]: value });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="dashboard-wrapper">
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <ul className="sidebar-list">
          <li>
            <Link to="/" style={{ textDecoration: 'none', color: 'aqua' }}>
              <FaHome className="sidebar-icon" />
              <span style={{ marginLeft: '10px' }}>Dashboard</span>
            </Link>
          </li>
          <li onClick={toggleHealth}>
            <FaHandHoldingMedical className="sidebar-icon" />
            <span>Health Questionnaires</span>
          </li>
          {openHealth && (
            <ul className="sidebar-sublist">
              <li><Link to="/forms/QuestionnaireAdult">Adult</Link></li>
              <li><Link to="/forms/QuestionnaireSmall">Child(6-7yrs)</Link></li>
              <li><Link to="/forms/QuestionnaireMid">Pediatric(13-14yrs)</Link></li>
            </ul>
          )}
          <li onClick={toggleIAQ}>
            <FaChartLine className="sidebar-icon" />
            <span>IAQ Questionnaires</span>
          </li>
          {openIAQ && (
            <ul className="sidebar-sublist">
              <li><Link to="/forms/SchoolQues">School</Link></li>
              <li><Link to="/forms/RestaurantObservationForm">Restaurant</Link></li>
              <li><Link to="/forms/ResidentialQues">Residential</Link></li>
              <li><Link to="/forms/OfficeBuilding">Office Building</Link></li>
              <li><Link to="/forms/MallForm">Malls</Link></li>
              <li><Link to="/forms/CinemaHallObservationForm">Cinema Hall</Link></li>
            </ul>
          )}
        
          {openFilters && (
            <ul className="sidebar-sublist">
              <li>
                <button onClick={() => handleFilterChange('location')}>
                  Location
                </button>
                <select onChange={(e) => handleFilterChange('location', e.target.value)}>
                  <option value="">Select Location</option>
                  {locationIds.map((id, index) => (
                    <option key={index} value={id}>
                      {id}
                    </option>
                  ))}
                </select>
              </li>
              <li>
                <button onClick={() => handleFilterChange('category')}>
                  Category
                </button>
              </li>
              <li>
                <button onClick={() => handleFilterChange('subcategory')}>
                  Subcategory
                </button>
              </li>
              <li>
                <button onClick={() => handleFilterChange('criteria')}>
                  Criteria
                </button>
              </li>
            </ul>
          )}
        </ul>
      </div>
      <div className="dashboard-container">
        <div className="hamburger" onClick={toggleSidebar}>
          <FaBars />
        </div>
        <div className="graph-locations">
          <div className="card">
            <div className="card-body">
              <GraphLocationsdata />
            </div>
            <div>
              <ParticipantAgeRangeChart />
            </div>
            <div>
              <GenderCountsChart />
            </div>
            <div>
              <LungOscillometryChart />
            </div>
            <hr></hr>
           
          </div>
        </div>
        <div className="data-container">
          <div className="cardd">
            <div className="card-bodyy">
              <GrimmsData />
            </div>
          </div>
          <div className="cardd">
          <div className="card-bodyyy">
              <TigerxtData />
            </div>
          </div>
          <div className="cardd">
            <div className="card-bodyy">
              <TestoData />
            </div>
          </div>
       
        </div>
      </div>
    
    </div>
  );
};

export default Dashboard;
