import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import '../css/common.css';

const RestaurantObservationForm = () => {
  const [observation, setObservation] = useState({
    observationDate: "",
    locationId: "",
    participantId: "",
    locationAddress: "",
    occupantsCount: "",
    sittingCapacity: "",
    activeHours: "",
    workingHours: "",
    staffCount: "",
    odourPresent: "",
    odourType: "",
    otherOdourType: "",
    odourFrequency: "",
    ventilationType: "",
    ductsMaintenanceFrequency: "",
    noOfVents: "",
    manualCleaningFrequency: "",
    equipmentCleaningFrequency: "",
    chemicalCleaning: "",
    cleaningAgents: "",
    otherCleaningAgents: "",
    flooringType: "",
    otherFlooringType: "",
    carpetCondition: "",
    carpetConditionRemarks: "",
    furnitureType: "",
    otherFurnitureType: "",
    servingAreaCondition: "",
    smokePresence: "",
    stovesPresent: "",
    servingAreaPartition: "",
    remarkText: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObservation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request with observation data
      const response = await axios.post(
        // "http://localhost:8081/restaurant/observations/add",
        "http://89.116.20.162:8081/restaurant/observations/add",

        observation
      );
      console.log(response.data);
      // Reset observation state after successful submission
      setObservation({
        observationDate: "",
        locationId: "",
        participantId: "",
        locationAddress: "",
        occupantsCount: "",
        sittingCapacity: "",
        activeHours: "",
        workingHours: "",
        staffCount: "",
        odourPresent: "",
        odourType: "",
        otherOdourType: "",
        odourFrequency: "",
        ventilationType: "",
        ductsMaintenanceFrequency: "",
        noOfVents: "",
        manualCleaningFrequency: "",
        equipmentCleaningFrequency: "",
        chemicalCleaning: "",
        cleaningAgents: "",
        otherCleaningAgents: "",
        flooringType: "",
        otherFlooringType: "",
        carpetCondition: "",
        carpetConditionRemarks: "",
        furnitureType: "",
        otherFurnitureType: "",
        servingAreaCondition: "",
        smokePresence: "",
        stovesPresent: "",
        servingAreaPartition: "",
        remarkText: "",
      });
      alert("Observation submitted successfully!");
    } catch (error) {
      console.error("Error submitting observation:", error);
      alert(
        "An error occurred while submitting the observation. Please try again."
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <center>
          <h1>Restaurant</h1>
        </center>

        <label>
          Location ID:
          <input
            type="text"
            name="locationId"
            value={observation.locationId}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Participant ID:
          <input
            type="text"
            name="participantId"
            value={observation.participantId}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Date of Observation:
          <input
            type="date"
            name="observationDate"
            value={observation.observationDate}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Location Address:
          <input
            type="text"
            name="locationAddress"
            value={observation.locationAddress}
            onChange={handleChange}
            required
          />
        </label>
        <h3> Occupancy and Staffing Details</h3>
        <label>
          Number of occupants present during observation:
          <select
            name="occupantsCount"
            value={observation.occupantsCount}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<10">&lt;10</option>
            <option value="10-30">10-30</option>
            <option value="30-50">30-50</option>
            <option value=">50">&gt;50</option>
          </select>
        </label>

        <label>
          Sitting Capacity:
          <select
            name="sittingCapacity"
            value={observation.sittingCapacity}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<20">&lt;20</option>
            <option value="20-50">20-50</option>
            <option value="50-80">50-80</option>
            <option value=">80">&gt;80</option>
          </select>
        </label>

        <label>
          Average active hours of the restaurant/eatery:
          <select
            name="activeHours"
            value={observation.activeHours}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<8 hours">&lt;8 hours</option>
            <option value="8-12 hours">8-12 hours</option>
            <option value="12-16 hours">12-16 hours</option>
            <option value=">16 hours">&gt;16 hours</option>
          </select>
        </label>

        <label>
          Average Working Hours of Staff:
          <select
            name="workingHours"
            value={observation.workingHours}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<8 hours">&lt;8 hours</option>
            <option value="8-12 hours">8-12 hours</option>
            <option value=">12 hours">&gt;12 hours</option>
          </select>
        </label>

        <label>
          Average Staff Working in Serving Area:
          <select
            name="staffCount"
            value={observation.staffCount}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<10">&lt;10</option>
            <option value="10-20">10-20</option>
            <option value="20-30">20-30</option>
            <option value=">30">&gt;30</option>
          </select>
        </label>

        <label>
          Presence of any obvious odour:
          <select
            name="odourPresent"
            value={observation.odourPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {observation.odourPresent === "Yes" && (
          <>
            <label>
              Odour Type:
              <select
                name="odourType"
                value={observation.odourType}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Incense sticks">Incense sticks</option>
                <option value="Room Spray">Room Spray</option>
                <option value="Scented candles">Scented candles</option>
                <option value="Coal">Coal</option>
                <option value="Other">Others</option>
              </select>
            </label>

            {observation.odourType === "Other" && (
              <label>
                Other Odour Type:
                <input
                  type="text"
                  name="otherOdourType"
                  value={observation.otherOdourType}
                  onChange={handleChange}
                  required
                />
              </label>
            )}
          </>
        )}

        <label>
          Frequency of use of such products per day:
          <select
            name="odourFrequency"
            value={observation.odourFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="0">0</option>
            <option value="1-2">1-2</option>
            <option value="2-3">2-3</option>
            <option value="3-4">3-4</option>
            <option value=">4">&gt;4</option>
          </select>
        </label>

        <label>
          Ventilation Type:
          <select
            name="ventilationType"
            value={observation.ventilationType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Natural">Natural</option>
            <option value="Artificial (HVAC)">Artificial (HVAC)</option>
          </select>
        </label>

        <label>
          Ducts Maintenance Frequency:
          <select
            name="ductsMaintenanceFrequency"
            value={observation.ductsMaintenanceFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Recently">Recently</option>
            <option value="Once a year">Once a year</option>
            <option value="2 to 3 years">2 to 3 years</option>
            <option value="None">None</option>
          </select>
        </label>

        <label>
          Number of Vents:
          <select
            name="noOfVents"
            value={observation.noOfVents}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<5">&lt;5</option>
            <option value="5-10">5-10</option>
            <option value="10-20">10-20</option>
            <option value=">20">&gt;20</option>
          </select>
        </label>

        <h3>Cleaning Activities</h3>

        <label>
          How many times manually:
          <select
            name="manualCleaningFrequency"
            value={observation.manualCleaningFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1-2">1-2</option>
            <option value="2-4">2-4</option>
            <option value=">4">&gt;4</option>
          </select>
        </label>

        <label>
          How many times by equipment:
          <select
            name="equipmentCleaningFrequency"
            value={observation.equipmentCleaningFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1-2">1-2</option>
            <option value="2-4">2-4</option>
            <option value=">4">&gt;4</option>
          </select>
        </label>

        <label>
          Chemical-based Cleaning:
          <select
            name="chemicalCleaning"
            value={observation.chemicalCleaning}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {observation.chemicalCleaning === "Yes" && (
          <>
            <label>
              Cleaning Agents:
              <select
                name="cleaningAgents"
                value={observation.cleaningAgents}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Detergent">Detergent</option>
                <option value="Phenyl">Phenyl</option>
                <option value="Bleaching agents">Bleaching agents</option>
                <option value="Other">Others</option>
              </select>
            </label>

            {observation.cleaningAgents === "Other" && (
              <label>
                Other Cleaning Agents:
                <input
                  type="text"
                  name="otherCleaningAgents"
                  value={observation.otherCleaningAgents}
                  onChange={handleChange}
                  required
                />
              </label>
            )}
          </>
        )}

        <label>
          Type of Flooring Material:
          <select
            name="flooringType"
            value={observation.flooringType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Hardwood">Hardwood</option>
            <option value="Tile">Tile</option>
            <option value="Carpet">Carpet</option>
            <option value="Other">Others</option>
          </select>
        </label>

        {observation.flooringType === "Other" && (
          <label>
            Other Flooring Type:
            <input
              type="text"
              name="otherFlooringType"
              value={observation.otherFlooringType}
              onChange={handleChange}
              required
            />
          </label>
        )}

        <label>
          Presence of carpeting and its condition:
          <select
            name="carpetCondition"
            value={observation.carpetCondition}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Clean">Clean</option>
            <option value="Stained">Stained</option>
          </select>
        </label>

        <label>
          Carpet Condition Remarks:
          <input
            type="text"
            name="carpetConditionRemarks"
            value={observation.carpetConditionRemarks}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Type of furniture and upholstery materials:
          <select
            name="furnitureType"
            value={observation.furnitureType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Wood">Wood</option>
            <option value="Metal">Metal</option>
            <option value="Fabric">Fabric</option>
            <option value="Other">Other</option>
          </select>
        </label>

        {observation.furnitureType === "Other" && (
          <label>
            Other Furniture Type:
            <input
              type="text"
              name="otherFurnitureType"
              value={observation.otherFurnitureType}
              onChange={handleChange}
              required
            />
          </label>
        )}

        <label>
          Is the serving area air-conditioned (AC) or non-air-conditioned
          (Non-AC)?
          <select
            name="servingAreaCondition"
            value={observation.servingAreaCondition}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="AC">AC</option>
            <option value="Non-AC">Non-AC</option>
          </select>
        </label>

        <label>
          Is there any presence of smoke in the serving area?
          <select
            name="smokePresence"
            value={observation.smokePresence}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Are stoves present on tables for heating food?
          <select
            name="stovesPresent"
            value={observation.stovesPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Is there any partition separating the serving area from the kitchen?
          <select
            name="servingAreaPartition"
            value={observation.servingAreaPartition}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Remark Text:
          <input
            type="text"
            name="remarkText"
            value={observation.remarkText}
            onChange={handleChange}
            required
          />
        </label>

        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default RestaurantObservationForm;
