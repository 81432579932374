// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import ApexCharts from 'react-apexcharts';
// import '.././components/css/participantschart.css';

// const ParticipantAgeRangeChart = () => {
//   const [aggregatedCounts, setAggregatedCounts] = useState(null);
//   const [totalParticipants, setTotalParticipants] = useState(null);
//   const chartRef = useRef(null);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get('http://89.116.20.162:8081/aggregatedCounts');
//       setAggregatedCounts(response.data);
//       const total = response.data.age6to8Count + response.data.age9to13Count + response.data.age14to15Count + response.data.age16to17Count + response.data.age18Count + response.data.age18plusCount;
//       setTotalParticipants(total);
//     } catch (error) {
//       console.error('Error fetching aggregated counts:', error);
//     }
//   };

//   const options = {
//     chart: {
//       type: 'line',
//       height: 350,
//       zoom: {
//         enabled: false,
//       },
//     },
//     stroke: {
//       curve: 'smooth', // Makes the line wavy
//     },
//     series: [
//       {
//         name: 'Agewise Count',
//         data: [
//           aggregatedCounts?.age6to8Count,
//           aggregatedCounts?.age9to13Count,
//           aggregatedCounts?.age14to15Count,
//           aggregatedCounts?.age16to17Count,
//           aggregatedCounts?.age18Count,
//           aggregatedCounts?.age18plusCount,
//         ],
//       },
//     ],
//     xaxis: {
//       title: {
//         text: "Age-range",
//       },
//       categories: ['6-8', '9-13', '14-15', '16-17', '18', '18+'],
//     },
//     tooltip: {
//       y: {
//         formatter: (value) => `Count: ${value}`,
//       },
//     },
//   };

//   return (
//     <div className="chart-container" ref={chartRef}>
//       {aggregatedCounts ? (
//         <div>
//           <h2 className="chart-title">Agewise Count</h2>
//           <div className="chart-participants">
//             <ApexCharts options={options} series={options.series} type="line" height={chartRef.current?.clientHeight} />
//           </div>
//           <div className="total-participants">
//             Total Participants: {totalParticipants}
//           </div>
//         </div>
//       ) : (
//         <div className="loader">Loading...</div>
//       )}
//     </div>
//   );
// };

// export default ParticipantAgeRangeChart;









// For Total Participants Number

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';
import '../components/css/participantschart.css';

const ParticipantAgeRangeChart = () => {
  const [aggregatedCounts, setAggregatedCounts] = useState(null);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [chartDimensions, setChartDimensions] = useState({ width: 600, height: 360 });

  const chartRef = useRef(null);

  useEffect(() => {
    fetchData();
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://89.116.20.162:8081/aggregatedCounts');
      const data = response.data;
      setAggregatedCounts(data);

      const total = (data.age6to8Count || 0) + (data.age9to13Count || 0) + (data.age14to15Count || 0) + (data.age16to17Count || 0) + (data.age18Count || 0) + (data.age18plusCount || 0);
      setTotalParticipants(total);
    } catch (error) {
      console.error('Error fetching aggregated counts:', error);
    }
  };

  const handleResize = () => {
    const chartContainerWidth = chartRef.current ? chartRef.current.clientWidth : 0;
    const width = chartContainerWidth < 500 ? chartContainerWidth : Math.min(600, chartContainerWidth);
    const height = width * 0.6; // Maintain aspect ratio
    setChartDimensions({ width, height });
  };

  return (
    <div className="chart-container" ref={chartRef}>
      {aggregatedCounts ? (
        <div>
          <h2 className="chart-title">Health</h2>
          <div className="chart">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={[
                  { name: '6-8', 'Agewise Count': aggregatedCounts.age6to8Count },
                  { name: '9-13', 'Agewise Count': aggregatedCounts.age9to13Count },
                  { name: '14-15', 'Agewise Count': aggregatedCounts.age14to15Count },
                  { name: '16-17', 'Agewise Count': aggregatedCounts.age16to17Count },
                  { name: '18', 'Agewise Count': aggregatedCounts.age18Count },
                  { name: '18+', 'Agewise Count': aggregatedCounts.age18plusCount },
                ]}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" label={{ value: 'Age', position: 'insideBottom', offset: -5, style: { fill: 'green' } }} />
                <YAxis label={{ value: '', angle: -90, style: { fill: 'green' } }} />
                <Tooltip />
                <Legend align="right" />
                <Bar dataKey="Agewise Count" fill="#60afa6 ">
                  <LabelList dataKey="Agewise Count" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="total-participants">
            Total Participants: {totalParticipants}
          </div>
        </div>
      ) : (
        <div className="loader">Loading...</div>
      )}
    </div>
  );
};

export default ParticipantAgeRangeChart;

