import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '.././components/css/GenderCountsChart.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28']; // Colors for different categories

const SemicircularPieChart = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://89.116.20.162:8081/genderCounts'); // Replace with your API endpoint
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const pieData = [
    { name: 'Male', value: data?.maleCount || 0 },
    { name: 'Female', value: data?.femaleCount || 0 },
    { name: 'Others', value: data?.otherCount || 0 },
  ];

  return (
    <div className="pie-chart-container">
      {data ? (
        <div  className="">
          <h2 className="chart-title">Gender Distribution</h2>
          <div>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={100} // Adjust for doughnut thickness
                  outerRadius={150} // Adjust for the size of the chart
                  fill="#8884d8"
                  label
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="loader">Loading...</div>
      )}
    </div>
  );
};

export default SemicircularPieChart;
