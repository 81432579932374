import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import GrimmsData from './components/samplinggraphs/GrimmsData';
import TestoData from './components/samplinggraphs/TestoData';
import TigerxtData from './components/samplinggraphs/TigerxtData';
import QuestionnaireAdult from './components/Forms/QuestionnaireAdult';
import QuestionnaireSmall from './components/Forms/QuestionnaireSmall';
import QuestionnaireMid from './components/Forms/QuestionnaireMid';
import SchoolQues from './components/Forms/SchoolQues';
import RestaurantObservationForm from './components/Forms/RestaurantObservationForm';
import ResidentialQues from './components/Forms/ResidentialQues';
import OfficeBuilding from './components/Forms/OfficeBuilding';
import MallForm from './components/Forms/MallForm';
import CinemaHallObservationForm from './components/Forms/CinemaHallObservationForm';
import './App.css';
const App = () => {
  return (
    <Router>
      <Navbar />
      {/* <Sidebar /> */}
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/samplinggraphs/GrimmsData" element={<GrimmsData />} />
        <Route path="/samplinggraphs/TestoData" element={<TestoData />} />
        <Route path="/samplinggraphs/TigerxtData" element={<TigerxtData />} />
        <Route path="/forms/QuestionnaireAdult" element={<QuestionnaireAdult />} />
        <Route path="/forms/QuestionnaireSmall" element={<QuestionnaireSmall />} />
        <Route path="/forms/QuestionnaireMid" element={<QuestionnaireMid />} />
        <Route path="/forms/SchoolQues" element={<SchoolQues />} />
        <Route path="/forms/RestaurantObservationForm" element={<RestaurantObservationForm />} />
        <Route path="/forms/ResidentialQues" element={<ResidentialQues />} />
        <Route path="/forms/OfficeBuilding" element={<OfficeBuilding />} />
        <Route path="/forms/MallForm" element={<MallForm />} />
        <Route path="/forms/CinemaHallObservationForm" element={<CinemaHallObservationForm />} />
      </Routes>
    </Router>
  );
};

export default App;
