import React from 'react';
import ApexCharts from 'react-apexcharts';
import '../css/BarGraph.css';

const BarGraph = ({ data }) => {
  // Prepare the data for ApexCharts
  const series = [
    {
      name: 'Count',
      data: data.map(item => item.count), // Extract the count values
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 400,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val, // Display the count value
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758']
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.map(item => item.category), // Extract categories
      title: {
        text: 'Category',
      },
    },
    yaxis: {
      title: {
        text: 'Numbers',
      },
      labels: {
        formatter: (value) => Math.abs(value), // Format Y-axis labels
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (value) => `Count: ${value}`, // Format tooltip content
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
  };

  return (
    <div className='barchart-container'>
      <h2 style={{ textAlign: 'center', paddingBottom: '16px', paddingTop: '5px', fontWeight: 'bold' }}>
        Targets achieved
      </h2>
      <div className='chart-wrapper'>
        <ApexCharts options={options} series={series} type="bar" height={400} />
      </div>
    </div>
  );
};

export default BarGraph;
