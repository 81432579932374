///////////////////////////////////////// BAR CHART//////////////////////////////////

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "../css/TestoData.css";

ChartJS.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const TestoData = () => {
  const [data, setData] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const [selectedLocationId, setSelectedLocationId] = useState("Lall");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    selectedLocationId,
    selectedCategory,
    selectedSubCategory,
    selectedCriteria,
    selectedSeason,
  ]);

  const fetchFilters = async () => {
    try {
      const [
        locationResponse,
        categoryResponse,
        subCategoryResponse,
        criteriaResponse,
        seasonResponse,
      ] = await Promise.all([
        axios.get("http://89.116.20.162:8081/api/testo/locations"),
        axios.get("http://89.116.20.162:8081/api/testo/categories"),
        axios.get("http://89.116.20.162:8081/api/testo/subcategories"),
        axios.get("http://89.116.20.162:8081/api/testo/criteria"),
        axios.get("http://89.116.20.162:8081/api/testo/seasons"),

        // axios.get("http://localhost:8081/api/testo/locations"),
        // axios.get("http://localhost:8081/api/testo/categories"),
        // axios.get("http://localhost:8081/api/testo/subcategories"),
        // axios.get("http://localhost:8081/api/testo/criteria"),
        // axios.get("http://localhost:8081/api/testo/seasons"),
      ]);

      setLocationIds(locationResponse.data);
      setCategories(categoryResponse.data);
      setSubCategories(subCategoryResponse.data);
      setCriteriaList(criteriaResponse.data);
      setSeasons(seasonResponse.data);
    } catch (error) {
      console.error("Error fetching filter data:", error);
      setError("Failed to fetch filter data");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError("");
    try {
      const endpoint = "http://89.116.20.162:8081/api/testo/filtered";
      const params = new URLSearchParams();
      const locationId =
        selectedLocationId === "" ? "Lall" : selectedLocationId;

      if (locationId) params.append("locationId", locationId);
      if (selectedCategory) params.append("category", selectedCategory);
      if (selectedSubCategory)
        params.append("subCategory", selectedSubCategory);
      if (selectedCriteria) params.append("criteria", selectedCriteria);
      if (selectedSeason) params.append("season", selectedSeason);

      const response = await axios.get(endpoint, { params });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching Testo data:", error);
      setError("Failed to fetch Testo data");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const createChartData = (label, key, backgroundColor) => {
    return {
      labels: data.map((entry) =>
        new Date(entry.dateTime).toLocaleDateString()
      ),
      datasets: [
        {
          label: label,
          data: data.map((entry) => entry[key]),
          backgroundColor: backgroundColor,
        },
      ],
    };
  };

  const chartOptions = () => ({
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          callback: () => "", // Set all x-axis ticks to display 'Date'
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Values",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        mode: "nearest",
        intersect: true,
      },
    },
  });

  // Function to calculate min and max values for a parameter
  const calculateMinMax = (key) => {
    if (data.length === 0) return { min: "-", max: "-" };

    const values = data.map((entry) => entry[key]);
    return {
      min: Math.min(...values),
      max: Math.max(...values),
    };
  };

  return (
    <div className="testo-container">
      <h2 className="testo-heading">Comfort Parameters</h2>
      {error && <p className="error-message">{error}</p>}
      <button className="dropdown-toggle" onClick={toggleFilters}>
        {isFiltersVisible ? "Hide Filters" : "Show Filters"}
      </button>
      {isFiltersVisible && (
        <div className="filters-content">
          <div className="filter-group">
            <select
              id="locationSelect"
              onChange={handleFilterChange(setSelectedLocationId)}
              value={selectedLocationId}
            >
              <option value="">All Locations</option>
              {locationIds.map((id) => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select
              id="categorySelect"
              onChange={handleFilterChange(setSelectedCategory)}
              value={selectedCategory}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select
              id="subCategorySelect"
              onChange={handleFilterChange(setSelectedSubCategory)}
              value={selectedSubCategory}
            >
              <option value="">All Sub Categories</option>
              {subCategories.map((subCategory) => (
                <option key={subCategory} value={subCategory}>
                  {subCategory}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select
              id="criteriaSelect"
              onChange={handleFilterChange(setSelectedCriteria)}
              value={selectedCriteria}
            >
              <option value="">All Criteria</option>
              {criteriaList.map((criteria) => (
                <option key={criteria} value={criteria}>
                  {criteria}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select
              id="seasonSelect"
              onChange={handleFilterChange(setSelectedSeason)}
              value={selectedSeason}
            >
              <option value="">All Seasons</option>
              {seasons.map((season) => (
                <option key={season} value={season}>
                  {season}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="chart-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <h3>CO2(ppm)</h3>
            <Bar
              data={createChartData("CO2 Levels", "co2", "rgba(75,192,192,1)")}
              options={chartOptions()}
            />

            <h3>CO(ppm)</h3>
            <Bar
              data={createChartData("CO Levels", "co", "rgba(255,99,132,1)")}
              options={chartOptions()}
            />

            <h3>Relative Humidity(%)</h3>
            <Bar
              data={createChartData(
                "Relative Humidity",
                "relativeHumidity",
                "rgba(54,162,235,1)"
              )}
              options={chartOptions()}
            />

            <h3>Temperature(°C)</h3>
            <Bar
              data={createChartData(
                "Temperature",
                "temperature",
                "rgba(75,192,192,1)"
              )}
              options={chartOptions()}
            />

            <h3>Wind Velocity(m/s)</h3>
            <Bar
              data={createChartData(
                "Wind Velocity",
                "windVelocity",
                "rgba(153,102,255,1)"
              )}
              options={chartOptions()}
            />

            <h3>Pressure(bar)</h3>
            <Bar
              data={createChartData(
                "Pressure",
                "pressure",
                "rgba(255,159,64,1)"
              )}
              options={chartOptions()}
            />
          </>
        )}
      </div>

      {/* Table for Min and Max values */}
      <div className="max-min-values">
        <h3>Statistics</h3>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>MAX Value</th>
              <th>MIN Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CO2 Levels</td>
              <td>{calculateMinMax("co2").max}</td>
              <td>{calculateMinMax("co2").min}</td>
            </tr>
            <tr>
              <td>CO Levels</td>
              <td>{calculateMinMax("co").max}</td>
              <td>{calculateMinMax("co").min}</td>
            </tr>
            <tr>
              <td>Relative Humidity</td>
              <td>{calculateMinMax("relativeHumidity").max}</td>
              <td>{calculateMinMax("relativeHumidity").min}</td>
            </tr>
            <tr>
              <td>Temperature</td>
              <td>{calculateMinMax("temperature").max}</td>
              <td>{calculateMinMax("temperature").min}</td>
            </tr>
            <tr>
              <td>Wind Velocity</td>
              <td>{calculateMinMax("windVelocity").max}</td>
              <td>{calculateMinMax("windVelocity").min}</td>
            </tr>
            <tr>
              <td>Pressure</td>
              <td>{calculateMinMax("pressure").max}</td>
              <td>{calculateMinMax("pressure").min}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestoData;

//////////////////////////////////////////////////////////////// LINE CHART ///////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Line } from 'react-chartjs-2';
// import 'chartjs-adapter-date-fns';
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   TimeScale,
// } from 'chart.js';
// import '../css/TestoData.css';

// ChartJS.register(
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   TimeScale
// );

// const TestoData = () => {
//   const [data, setData] = useState([]);
//   const [locationIds, setLocationIds] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [subCategories, setSubCategories] = useState([]);
//   const [criteriaList, setCriteriaList] = useState([]);
//   const [seasons, setSeasons] = useState([]);

//   const [selectedLocationId, setSelectedLocationId] = useState('Lall'); // Default to 'Lall'
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedSubCategory, setSelectedSubCategory] = useState('');
//   const [selectedCriteria, setSelectedCriteria] = useState('');
//   const [selectedSeason, setSelectedSeason] = useState('');

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [isFiltersVisible, setIsFiltersVisible] = useState(false); // Filters hidden by default

//   const [maxCo2, setMaxCo2] = useState(null);
//   const [maxCo, setMaxCo] = useState(null);
//   const [maxRelativeHumidity, setMaxRelativeHumidity] = useState(null);
//   const [maxTemperature, setMaxTemperature] = useState(null);
//   const [maxWindVelocity, setMaxWindVelocity] = useState(null);
//   const [maxPressure, setMaxPressure] = useState(null);

//   const [minCo2, setMinCo2] = useState(null);
//   const [minCo, setMinCo] = useState(null);
//   const [minRelativeHumidity, setMinRelativeHumidity] = useState(null);
//   const [minTemperature, setMinTemperature] = useState(null);
//   const [minWindVelocity, setMinWindVelocity] = useState(null);
//   const [minPressure, setMinPressure] = useState(null);

//   useEffect(() => {
//     fetchFilters();
//   }, []);

//   useEffect(() => {
//     fetchData();
//   }, [selectedLocationId, selectedCategory, selectedSubCategory, selectedCriteria, selectedSeason]);

//   const fetchFilters = async () => {
//     try {
//       const [locationResponse, categoryResponse, subCategoryResponse, criteriaResponse, seasonResponse] = await Promise.all([
//         // axios.get('http://192.168.1.23:8081/api/testo/locations'),
//         // axios.get('http://192.168.1.23:8081/api/testo/categories'),
//         // axios.get('http://192.168.1.23:8081/api/testo/subcategories'),
//         // axios.get('http://192.168.1.23:8081/api/testo/criteria'),
//         // axios.get('http://192.168.1.23:8081/api/testo/seasons')

//         axios.get('http://localhost:8081/api/testo/locations'),
//         axios.get('http://localhost:8081/api/testo/categories'),
//         axios.get('http://localhost:8081/api/testo/subcategories'),
//         axios.get('http://localhost:8081/api/testo/criteria'),
//         axios.get('http://localhost:8081/api/testo/seasons')
//       ]);

//       setLocationIds(locationResponse.data);
//       setCategories(categoryResponse.data);
//       setSubCategories(subCategoryResponse.data);
//       setCriteriaList(criteriaResponse.data);
//       setSeasons(seasonResponse.data);
//     } catch (error) {
//       console.error('Error fetching filter data:', error);
//       setError('Failed to fetch filter data');
//     }
//   };

//   const fetchData = async () => {
//     setLoading(true);
//     setError('');
//     try {
//       const endpoint = 'http://localhost:8081/api/testo/filtered';

//       // const endpoint = 'http://192.168.1.23:8081/api/testo/filtered';

//       const params = new URLSearchParams();

//       // Treat "All Locations" as "Lall"
//       const locationId = selectedLocationId === '' ? 'Lall' : selectedLocationId;

//       if (locationId) params.append('locationId', locationId);
//       if (selectedCategory) params.append('category', selectedCategory);
//       if (selectedSubCategory) params.append('subCategory', selectedSubCategory);
//       if (selectedCriteria) params.append('criteria', selectedCriteria);
//       if (selectedSeason) params.append('season', selectedSeason);

//       const response = await axios.get(endpoint, { params });
//       setData(response.data);
//       calculateMaxMinValues(response.data);
//     } catch (error) {
//       console.error('Error fetching Testo data:', error);
//       setError('Failed to fetch Testo data');
//     } finally {
//       setLoading(false);
//     }
//   };

//   /*
//     const fetchData = async () => {
//     setLoading(true);
//     setError('');
//     try {
//       let endpoint = 'http://localhost:8081/api/testo/filtered';
//       const params = new URLSearchParams();

//       if (selectedLocationId) params.append('locationId', selectedLocationId);
//       if (selectedCategory) params.append('category', selectedCategory);
//       if (selectedSubCategory) params.append('subCategory', selectedSubCategory);
//       if (selectedCriteria) params.append('criteria', selectedCriteria);
//       if (selectedSeason) params.append('season', selectedSeason);

//       const response = await axios.get(endpoint, { params });
//       setData(response.data);
//       calculateMaxMinValues(response.data);
//     } catch (error) {
//       console.error('Error fetching Testo data:', error);
//       setError('Failed to fetch Testo data');
//     } finally {
//       setLoading(false);
//     }
//   };*/
//   const handleFilterChange = (setter) => (event) => {
//     setter(event.target.value);
//   };

//   const toggleFilters = () => {
//     setIsFiltersVisible(!isFiltersVisible);
//   };

//   const getMinMaxValues = (key) => {
//     if (!data.length) return { min: 0, max: 100 }; // Default range if no data
//     const values = data.map(entry => entry[key]);
//     return {
//       min: Math.min(...values),
//       max: Math.max(...values),
//     };
//   };

//   const calculateMaxMinValues = (data) => {
//     if (data.length > 0) {
//       const co2Values = data.map(entry => entry.co2);
//       const coValues = data.map(entry => entry.co);
//       const relativeHumidityValues = data.map(entry => entry.relativeHumidity);
//       const temperatureValues = data.map(entry => entry.temperature);
//       const windVelocityValues = data.map(entry => entry.windVelocity);
//       const pressureValues = data.map(entry => entry.pressure);

//       setMaxCo2(Math.round(Math.max(...co2Values)));
//       setMaxCo(Math.round(Math.max(...coValues)));
//       setMaxRelativeHumidity(Math.round(Math.max(...relativeHumidityValues)));
//       setMaxTemperature(Math.round(Math.max(...temperatureValues)));
//       setMaxWindVelocity(Math.round(Math.max(...windVelocityValues)));
//       setMaxPressure(Math.round(Math.max(...pressureValues)));

//       setMinCo2(Math.round(Math.min(...co2Values)));
//       setMinCo(Math.round(Math.min(...coValues)));
//       setMinRelativeHumidity(Math.round(Math.min(...relativeHumidityValues)));
//       setMinTemperature(Math.round(Math.min(...temperatureValues)));
//       setMinWindVelocity(Math.round(Math.min(...windVelocityValues)));
//       setMinPressure(Math.round(Math.min(...pressureValues)));
//     } else {
//       setMaxCo2(null);
//       setMaxCo(null);
//       setMaxRelativeHumidity(null);
//       setMaxTemperature(null);
//       setMaxWindVelocity(null);
//       setMaxPressure(null);

//       setMinCo2(null);
//       setMinCo(null);
//       setMinRelativeHumidity(null);
//       setMinTemperature(null);
//       setMinWindVelocity(null);
//       setMinPressure(null);
//     }
//   };

//   const createChartData = (label, key, borderColor) => {
//     const { min, max } = getMinMaxValues(key);
//     return {
//       labels: data.map(entry => new Date(entry.dateTime)),
//       datasets: [
//         {
//           label: label,
//           data: data.map(entry => entry[key]),
//           borderColor: borderColor,
//           fill: false,
//           min,
//           max,
//         },
//       ],
//     };
//   };

//   const chartOptions = (min, max) => ({
//     responsive: true,
//     scales: {
//       x: {
//         type: 'time',
//         time: {
//           unit: 'day',
//           tooltipFormat: 'MM/dd/yyyy',
//         },
//         title: {
//           display: true,
//           text: 'Date',
//         },
//         ticks: {
//           display: false,
//         },
//         grid: {
//           display: false,
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: 'Values',
//         },
//         beginAtZero: true,
//         min: min,
//         max: max,
//       },
//     },
//     plugins: {
//       legend: {
//         display: true,
//         position: 'top',
//       },
//       tooltip: {
//         mode: 'index',
//         intersect: false,
//       },
//     },
//   });

//   return (
//     <div className="testo-container">
//       <h2 className="testo-heading">Comfort Parameters</h2>
//       {error && <p className="error-message">{error}</p>}
//       <button className="dropdown-toggle" onClick={toggleFilters}>
//         {isFiltersVisible ? 'Hide Filters' : 'Show Filters'}
//       </button>
//       {isFiltersVisible && (
//         <div className="filters-content">
//           <div className="filter-group">
//             <select id="locationSelect" onChange={handleFilterChange(setSelectedLocationId)} value={selectedLocationId}>
//               <option value="">All Locations</option>
//               {locationIds.map(id => (
//                 <option key={id} value={id}>{id}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="categorySelect" onChange={handleFilterChange(setSelectedCategory)} value={selectedCategory}>
//               <option value="">All Categories</option>
//               {categories.map(category => (
//                 <option key={category} value={category}>{category}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="subCategorySelect" onChange={handleFilterChange(setSelectedSubCategory)} value={selectedSubCategory}>
//               <option value="">All Sub Categories</option>
//               {subCategories.map(subCategory => (
//                 <option key={subCategory} value={subCategory}>{subCategory}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="criteriaSelect" onChange={handleFilterChange(setSelectedCriteria)} value={selectedCriteria}>
//               <option value="">All Criteria</option>
//               {criteriaList.map(criteria => (
//                 <option key={criteria} value={criteria}>{criteria}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="seasonSelect" onChange={handleFilterChange(setSelectedSeason)} value={selectedSeason}>
//               <option value="">All Seasons</option>
//               {seasons.map(season => (
//                 <option key={season} value={season}>{season}</option>
//               ))}
//             </select>
//           </div>
//         </div>
//       )}
//       <div className="chart-container">
//         {loading ? (
//           <p>Loading...</p>
//         ) : (
//           <>
//             <h3>CO2 Levels</h3>
//             <Line data={createChartData('CO2', 'co2', 'rgba(75,192,192,1)')} options={chartOptions(getMinMaxValues('co2').min, getMinMaxValues('co2').max)} />

//             <h3>CO Levels</h3>
//             <Line data={createChartData('CO', 'co', 'rgba(255,99,132,1)')} options={chartOptions(getMinMaxValues('co').min, getMinMaxValues('co').max)} />

//             <h3>Relative Humidity</h3>
//             <Line data={createChartData('Relative Humidity', 'relativeHumidity', 'rgba(54,162,235,1)')} options={chartOptions(getMinMaxValues('relativeHumidity').min, getMinMaxValues('relativeHumidity').max)} />

//             <h3>Temperature</h3>
//             <Line data={createChartData('Temperature', 'temperature', 'rgba(75,192,192,1)')} options={chartOptions(getMinMaxValues('temperature').min, getMinMaxValues('temperature').max)} />

//             <h3>Wind Velocity</h3>
//             <Line data={createChartData('Wind Velocity', 'windVelocity', 'rgba(153,102,255,1)')} options={chartOptions(getMinMaxValues('windVelocity').min, getMinMaxValues('windVelocity').max)} />

//             <h3>Pressure</h3>
//             <Line data={createChartData('Pressure', 'pressure', 'rgba(255,159,64,1)')} options={chartOptions(getMinMaxValues('pressure').min, getMinMaxValues('pressure').max)} />
//           </>
//         )}
//       </div>

//       <div className="max-min-values">
//   <h3>Max and Min Values</h3>
//   <table>
//     <thead>
//       <tr>
//         <th>Parameter</th>
//         <th>Max Value</th>
//         <th>Min Value</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>CO2</td>
//         <td>{maxCo2}</td>
//         <td>{minCo2}</td>
//       </tr>
//       <tr>
//         <td>CO</td>
//         <td>{maxCo}</td>
//         <td>{minCo}</td>
//       </tr>
//       <tr>
//         <td>Relative Humidity</td>
//         <td>{maxRelativeHumidity}</td>
//         <td>{minRelativeHumidity}</td>
//       </tr>
//       <tr>
//         <td>Temperature</td>
//         <td>{maxTemperature}</td>
//         <td>{minTemperature}</td>
//       </tr>
//       <tr>
//         <td>Wind Velocity</td>
//         <td>{maxWindVelocity}</td>
//         <td>{minWindVelocity}</td>
//       </tr>
//       <tr>
//         <td>Pressure</td>
//         <td>{maxPressure}</td>
//         <td>{minPressure}</td>
//       </tr>
//     </tbody>
//   </table>
// </div>

//     </div>
//   );
// };

// export default TestoData;

/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';
import '../css/TestoData.css';

ChartJS.register(LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, Title);

const TestoData = () => {
  const [data, setData] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedCriteria, setSelectedCriteria] = useState('');
  const [selectedSeason, setSelectedSeason] = useState('');

  const [maxCo2, setMaxCo2] = useState(null);
  const [maxCo, setMaxCo] = useState(null);
  const [maxRelativeHumidity, setMaxRelativeHumidity] = useState(null);
  const [maxTemperature, setMaxTemperature] = useState(null);
  const [maxWindVelocity, setMaxWindVelocity] = useState(null);
  const [maxPressure, setMaxPressure] = useState(null);

  const [minCo2, setMinCo2] = useState(null);
  const [minCo, setMinCo] = useState(null);
  const [minRelativeHumidity, setMinRelativeHumidity] = useState(null);
  const [minTemperature, setMinTemperature] = useState(null);
  const [minWindVelocity, setMinWindVelocity] = useState(null);
  const [minPressure, setMinPressure] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isFiltersVisible, setIsFiltersVisible] = useState(false); // Filters hidden by default

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedLocationId, selectedCategory, selectedSubCategory, selectedCriteria, selectedSeason]);

  const fetchFilters = async () => {
    try {
      const [locationResponse, categoryResponse, subCategoryResponse, criteriaResponse, seasonResponse] = await Promise.all([
        axios.get('http://localhost:8081/api/testo/locations'),
        axios.get('http://localhost:8081/api/testo/categories'),
        axios.get('http://localhost:8081/api/testo/subcategories'),
        axios.get('http://localhost:8081/api/testo/criteria'),
        axios.get('http://localhost:8081/api/testo/seasons')
      ]);

      setLocationIds(locationResponse.data);
      setCategories(categoryResponse.data);
      setSubCategories(subCategoryResponse.data);
      setCriteriaList(criteriaResponse.data);
      setSeasons(seasonResponse.data);
    } catch (error) {
      console.error('Error fetching filter data:', error);
      setError('Failed to fetch filter data');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError('');
    try {
      let endpoint = 'http://localhost:8081/api/testo/filtered';
      const params = new URLSearchParams();

      if (selectedLocationId) params.append('locationId', selectedLocationId);
      if (selectedCategory) params.append('category', selectedCategory);
      if (selectedSubCategory) params.append('subCategory', selectedSubCategory);
      if (selectedCriteria) params.append('criteria', selectedCriteria);
      if (selectedSeason) params.append('season', selectedSeason);

      const response = await axios.get(endpoint, { params });
      setData(response.data);
      calculateMaxMinValues(response.data);
    } catch (error) {
      console.error('Error fetching Testo data:', error);
      setError('Failed to fetch Testo data');
    } finally {
      setLoading(false);
    }
  };

  const calculateMaxMinValues = (data) => {
    if (data.length > 0) {
      const co2Values = data.map(entry => entry.co2);
      const coValues = data.map(entry => entry.co);
      const relativeHumidityValues = data.map(entry => entry.relativeHumidity);
      const temperatureValues = data.map(entry => entry.temperature);
      const windVelocityValues = data.map(entry => entry.windVelocity);
      const pressureValues = data.map(entry => entry.pressure);

      setMaxCo2(Math.round(Math.max(...co2Values)));
      setMaxCo(Math.round(Math.max(...coValues)));
      setMaxRelativeHumidity(Math.round(Math.max(...relativeHumidityValues)));
      setMaxTemperature(Math.round(Math.max(...temperatureValues)));
      setMaxWindVelocity(Math.round(Math.max(...windVelocityValues)));
      setMaxPressure(Math.round(Math.max(...pressureValues)));

      setMinCo2(Math.round(Math.min(...co2Values)));
      setMinCo(Math.round(Math.min(...coValues)));
      setMinRelativeHumidity(Math.round(Math.min(...relativeHumidityValues)));
      setMinTemperature(Math.round(Math.min(...temperatureValues)));
      setMinWindVelocity(Math.round(Math.min(...windVelocityValues)));
      setMinPressure(Math.round(Math.min(...pressureValues)));
    } else {
      setMaxCo2(null);
      setMaxCo(null);
      setMaxRelativeHumidity(null);
      setMaxTemperature(null);
      setMaxWindVelocity(null);
      setMaxPressure(null);

      setMinCo2(null);
      setMinCo(null);
      setMinRelativeHumidity(null);
      setMinTemperature(null);
      setMinWindVelocity(null);
      setMinPressure(null);
    }
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const createChartData = (label, dataKey, color) => ({
    labels: data.map(entry => new Date(entry.dateTime)),
    datasets: [
      {
        label: label,
        data: data.map(entry => entry[dataKey]),
        fill: false,
        backgroundColor: color,
        borderColor: color,
        tension: 0.1,
      }
    ],
  });

  return (
    <div className="testo-container">
      <h2 className="testo-heading">Comfort Parameters</h2>
      {error && <p className="error-message">{error}</p>}
      <button className="dropdown-toggle" onClick={toggleFilters}>
        {isFiltersVisible ? 'Hide Filters' : 'Show Filters'}
      </button>
      {isFiltersVisible && (
        <div className="filters-content">
          <div className="filter-group">
            <select id="locationSelect" onChange={handleFilterChange(setSelectedLocationId)} value={selectedLocationId}>
              <option value="">All Locations</option>
              {locationIds.map(id => (
                <option key={id} value={id}>{id}</option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select id="categorySelect" onChange={handleFilterChange(setSelectedCategory)} value={selectedCategory}>
              <option value="">All Categories</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select id="subCategorySelect" onChange={handleFilterChange(setSelectedSubCategory)} value={selectedSubCategory}>
              <option value="">All Subcategories</option>
              {subCategories.map(subCategory => (
                <option key={subCategory} value={subCategory}>{subCategory}</option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select id="criteriaSelect" onChange={handleFilterChange(setSelectedCriteria)} value={selectedCriteria}>
              <option value="">All Criteria</option>
              {criteriaList.map(criteria => (
                <option key={criteria} value={criteria}>{criteria}</option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <select id="seasonSelect" onChange={handleFilterChange(setSelectedSeason)} value={selectedSeason}>
              <option value="">All Seasons</option>
              {seasons.map(season => (
                <option key={season} value={season}>{season}</option>
              ))}
            </select>
          </div>
        </div>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="chart-container">
          <div className="chart">
            <Line data={createChartData('CO2', 'co2', 'rgba(75,192,192,1)')} />
          </div>
          <div className="chart">
            <Line data={createChartData('CO', 'co', 'rgba(255,99,132,1)')} />
          </div>
          <div className="chart">
            <Line data={createChartData('Relative Humidity', 'relativeHumidity', 'rgba(54,162,235,1)')} />
          </div>
          <div className="chart">
            <Line data={createChartData('Temperature', 'temperature', 'rgba(255,206,86,1)')} />
          </div>
          <div className="chart">
            <Line data={createChartData('Wind Velocity', 'windVelocity', 'rgba(153,102,255,1)')} />
          </div>
          <div className="chart">
            <Line data={createChartData('Pressure', 'pressure', 'rgba(255,159,64,1)')} />
          </div>
          <div className="max-min-values">
            <h3>Max and Min Values</h3>
            <ul>
              <li>Max CO2: {maxCo2}</li>
              <li>Min CO2: {minCo2}</li>
              <li>Max CO: {maxCo}</li>
              <li>Min CO: {minCo}</li>
              <li>Max Relative Humidity: {maxRelativeHumidity}</li>
              <li>Min Relative Humidity: {minRelativeHumidity}</li>
              <li>Max Temperature: {maxTemperature}</li>
              <li>Min Temperature: {minTemperature}</li>
              <li>Max Wind Velocity: {maxWindVelocity}</li>
              <li>Min Wind Velocity: {minWindVelocity}</li>
              <li>Max Pressure: {maxPressure}</li>
              <li>Min Pressure: {minPressure}</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestoData;

*/

// /*

// Bar graphs

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Bar } from 'react-chartjs-2';
// import 'chartjs-adapter-date-fns';
// import {
//   Chart as ChartJS,
//   BarController,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   TimeScale,
// } from 'chart.js';
// import '../css/TestoData.css';

// ChartJS.register(
//   BarController,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   TimeScale
// );

// const TestoData = () => {
//   const [data, setData] = useState([]);
//   const [locationIds, setLocationIds] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [subCategories, setSubCategories] = useState([]);
//   const [criteriaList, setCriteriaList] = useState([]);
//   const [seasons, setSeasons] = useState([]);

//   const [selectedLocationId, setSelectedLocationId] = useState('Lall');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedSubCategory, setSelectedSubCategory] = useState('');
//   const [selectedCriteria, setSelectedCriteria] = useState('');
//   const [selectedSeason, setSelectedSeason] = useState('');

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [isFiltersVisible, setIsFiltersVisible] = useState(false);

//   useEffect(() => {
//     fetchFilters();
//   }, []);

//   useEffect(() => {
//     fetchData();
//   }, [selectedLocationId, selectedCategory, selectedSubCategory, selectedCriteria, selectedSeason]);

//   const fetchFilters = async () => {
//     try {
//       const [locationResponse, categoryResponse, subCategoryResponse, criteriaResponse, seasonResponse] = await Promise.all([
//         axios.get('http://localhost:8081/api/testo/locations'),
//         axios.get('http://localhost:8081/api/testo/categories'),
//         axios.get('http://localhost:8081/api/testo/subcategories'),
//         axios.get('http://localhost:8081/api/testo/criteria'),
//         axios.get('http://localhost:8081/api/testo/seasons')
//       ]);

//       setLocationIds(locationResponse.data);
//       setCategories(categoryResponse.data);
//       setSubCategories(subCategoryResponse.data);
//       setCriteriaList(criteriaResponse.data);
//       setSeasons(seasonResponse.data);
//     } catch (error) {
//       console.error('Error fetching filter data:', error);
//       setError('Failed to fetch filter data');
//     }
//   };

//   const fetchData = async () => {
//     setLoading(true);
//     setError('');
//     try {
//       const endpoint = 'http://localhost:8081/api/testo/filtered';
//       const params = new URLSearchParams();
//       const locationId = selectedLocationId === '' ? 'Lall' : selectedLocationId;

//       if (locationId) params.append('locationId', locationId);
//       if (selectedCategory) params.append('category', selectedCategory);
//       if (selectedSubCategory) params.append('subCategory', selectedSubCategory);
//       if (selectedCriteria) params.append('criteria', selectedCriteria);
//       if (selectedSeason) params.append('season', selectedSeason);

//       const response = await axios.get(endpoint, { params });
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching Testo data:', error);
//       setError('Failed to fetch Testo data');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFilterChange = (setter) => (event) => {
//     setter(event.target.value);
//   };

//   const toggleFilters = () => {
//     setIsFiltersVisible(!isFiltersVisible);
//   };

//   const createChartData = (label, key, backgroundColor) => {
//     return {
//       labels: data.map(entry => new Date(entry.dateTime).toLocaleDateString()),
//       datasets: [
//         {
//           label: label,
//           data: data.map(entry => entry[key]),
//           backgroundColor: backgroundColor,
//         },
//       ],
//     };
//   };

//   const chartOptions = () => ({
//     responsive: true,
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: 'Date',
//         },
//         ticks: {
//           callback: () => '', // Set all x-axis ticks to display 'Date'
//         },
//         grid: {
//           display: false,
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: 'Values',
//         },
//         beginAtZero: true,
//       },
//     },
//     plugins: {
//       legend: {
//         display: true,
//         position: 'top',
//       },
//       tooltip: {
//         mode: 'nearest',
//         intersect: true,
//       },
//     },
//   });

//   return (
//     <div className="testo-container">
//       <h2 className="testo-heading">Comfort Parameters</h2>
//       {error && <p className="error-message">{error}</p>}
//       <button className="dropdown-toggle" onClick={toggleFilters}>
//         {isFiltersVisible ? 'Hide Filters' : 'Show Filters'}
//       </button>
//       {isFiltersVisible && (
//         <div className="filters-content">
//           <div className="filter-group">
//             <select id="locationSelect" onChange={handleFilterChange(setSelectedLocationId)} value={selectedLocationId}>
//               <option value="">All Locations</option>
//               {locationIds.map(id => (
//                 <option key={id} value={id}>{id}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="categorySelect" onChange={handleFilterChange(setSelectedCategory)} value={selectedCategory}>
//               <option value="">All Categories</option>
//               {categories.map(category => (
//                 <option key={category} value={category}>{category}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="subCategorySelect" onChange={handleFilterChange(setSelectedSubCategory)} value={selectedSubCategory}>
//               <option value="">All Sub Categories</option>
//               {subCategories.map(subCategory => (
//                 <option key={subCategory} value={subCategory}>{subCategory}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="criteriaSelect" onChange={handleFilterChange(setSelectedCriteria)} value={selectedCriteria}>
//               <option value="">All Criteria</option>
//               {criteriaList.map(criteria => (
//                 <option key={criteria} value={criteria}>{criteria}</option>
//               ))}
//             </select>
//           </div>
//           <div className="filter-group">
//             <select id="seasonSelect" onChange={handleFilterChange(setSelectedSeason)} value={selectedSeason}>
//               <option value="">All Seasons</option>
//               {seasons.map(season => (
//                 <option key={season} value={season}>{season}</option>
//               ))}
//             </select>
//           </div>
//         </div>
//       )}
//       <div className="chart-container">
//         {loading ? (
//           <p>Loading...</p>
//         ) : (
//           <>
//             <h3>CO2 Levels</h3>
//             <Bar data={createChartData('CO2 Levels', 'co2', 'rgba(0, 128, 0, 0.8)')} options={chartOptions()} />

//             <h3>CO Levels</h3>
//             <Bar data={createChartData('CO Levels', 'co', 'rgba(139, 0, 0, 0.8)')} options={chartOptions()} />

//             <h3>Relative Humidity</h3>
//             <Bar data={createChartData('Relative Humidity', 'relativeHumidity', 'rgba(0, 139, 139, 0.8)')} options={chartOptions()} />

//             <h3>Temperature</h3>
//             <Bar data={createChartData('Temperature', 'temperature', 'rgba(85, 107, 47, 0.8)')} options={chartOptions()} />

//             <h3>Wind Velocity</h3>
//             <Bar data={createChartData('Wind Velocity', 'windVelocity', 'rgba(153,102,255,0.4)')} options={chartOptions()} />

//             <h3>Pressure</h3>
//             <Bar data={createChartData('Pressure', 'pressure', 'rgba(255,159,64,0.4)')} options={chartOptions()} />
//           </>
//         )}
//       </div>

//       <div className="max-min-values">
//   <h3>Max and Min Values</h3>
//   <table>
//     <thead>
//       <tr>
//         <th>Parameter</th>
//         <th>Max Value</th>
//         <th>Min Value</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>CO2</td>
//         <td>{maxCo2}</td>
//         <td>{minCo2}</td>
//       </tr>
//       <tr>
//         <td>CO</td>
//         <td>{maxCo}</td>
//         <td>{minCo}</td>
//       </tr>
//       <tr>
//         <td>Relative Humidity</td>
//         <td>{maxRelativeHumidity}</td>
//         <td>{minRelativeHumidity}</td>
//       </tr>
//       <tr>
//         <td>Temperature</td>
//         <td>{maxTemperature}</td>
//         <td>{minTemperature}</td>
//       </tr>
//       <tr>
//         <td>Wind Velocity</td>
//         <td>{maxWindVelocity}</td>
//         <td>{minWindVelocity}</td>
//       </tr>
//       <tr>
//         <td>Pressure</td>
//         <td>{maxPressure}</td>
//         <td>{minPressure}</td>
//       </tr>
//     </tbody>
//   </table>
// </div>

//     </div>
//   );
// };

// export default TestoData;

// */
