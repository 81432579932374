import React from "react";
import { Link } from "react-router-dom";
import "../components/css/Navbar.css";
import logo from "../../src/components/assets/images/logo.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="navbar-heading">
        <h3>SAFE IAQ</h3>
      </div>
      <div className="nav-links">
        <button className="nav-button">
          <Link to="/">Home</Link>
        </button>
        {/* Add more buttons or links for other sections if needed */}
      </div>
    </div>
  );
};

export default Navbar;
