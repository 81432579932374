import React, { useState } from "react";
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import '../css/common.css';
import Sidebar from "../Sidebar.jsx";

const MallForm = () => {
  const [observation, setObservation] = useState({
    observerName: "",
    participantId:"",
    locationId:"",
    observationDate: "",
    buildingAddress: "",
    buildingAgeyears: "",
    newRenovation: "",
    mallTotalArea: "",
    occupantsTotal: "",
    numberOfShops: "",
    distanceCategory: "",
    foodCourtAndRestaurantsPresent: "",
    cookingFuel: "",
    ventilationType: "",
    ventilationOfWholeMall: "",
    totalNoOfWindows:"",
    ductsMaintenance: "",
    noOfVentsCategory: "",
    exhaustFanInletVelocity: "",
    exhaustFanOutletVelocity: "",
    cleaningMethod: "",
    manualCleaningFrequency: "",
    equipmentCleaningFrequency: "",
    chemicalProductsPresentForCleaning: "",
    chemicalProducts: "",
    otherChemicalProducts: "",
    employeeNumberInSamplingArea: "",
    otherFootballEachHourSamplingArea: "",
    averageFootballEachHourSamplingArea: "",
    furnitureMaterialInSamplingArea: "",
    furnitureNumberInSamplingArea: "",
    otherFurnitureNumberInSamplingArea: "",
    wallMaterialInSamplingArea: "",
    floorType: "",
    separateVentilationSystemMall: "",
    moistureOrDampPatchesMarks: "",
    specifyMoistureOrDampPatchesMarks: "",
    useOfScentedProducts: "",
    scentedProducts: "",
    otherScentedProducts: "",
    anySeperateTempHumidityControlSystem: "",
    ifYesAnySeperateTempHumidityControlSystem: "",
    maintenanceFrequency: "",
    otherMaintenanceFrequency: "",
    floorLevel: "",
    noOfWindowsNearSamplingLocation: "",
    curtainsPresent: "",
    mallDistanceFromTrafficSource: "",
    cafeteriaDistanceFromKitchen: "",
    airPurifierUsed: "",
    artificialPlantsPresentInMall: "",
    electronicsUsed: "",
    esclatorsOrLiftInMall: "",
    noOfEsclatorsInMall: "",
    noOfLiftsInMall: "",
    anyIndoorPlantsPresent: "",
    noOfIndoorPlantsPresent: "",
    other_observation: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObservation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request with observation data
      const response = await axios.post(
        // "http://localhost:8081/malls/add",
        "http://89.116.20.162:8081/malls/add",

        observation
      );
      console.log(response.data);
      // Reset observation state after successful submission
      setObservation({
        observerName: "",
        participantId:"",
        locationId:"",
        observationDate: "",
        buildingAddress: "",
        buildingAgeyears: "",
        newRenovation: "",
        mallTotalArea: "",
        occupantsTotal: "",
        numberOfShops: "",
        distanceCategory: "",
        foodCourtAndRestaurantsPresent: "",
        cookingFuel: "",
        ventilationType: "",
        ventilationOfWholeMall: "",
        totalNoOfWindows:"",
        ductsMaintenance: "",
        noOfVentsCategory: "",
        exhaustFanInletVelocity: "",
        exhaustFanOutletVelocity: "",
        cleaningMethod: "",
        manualCleaningFrequency: "",
        equipmentCleaningFrequency: "",
        chemicalProductsPresentForCleaning: "",
        chemicalProducts: "",
        otherChemicalProducts: "",
        employeeNumberInSamplingArea: "",
        otherFootballEachHourSamplingArea: "",
        averageFootballEachHourSamplingArea: "",
        furnitureMaterialInSamplingArea: "",
        furnitureNumberInSamplingArea: "",
        otherFurnitureNumberInSamplingArea: "",
        wallMaterialInSamplingArea: "",
        floorType: "",
        separateVentilationSystemMall: "",
        moistureOrDampPatchesMarks: "",
        specifyMoistureOrDampPatchesMarks: "",
        useOfScentedProducts: "",
        scentedProducts: "",
        otherScentedProducts: "",
        anySeperateTempHumidityControlSystem: "",
        ifYesAnySeperateTempHumidityControlSystem: "",
        maintenanceFrequency: "",
        otherMaintenanceFrequency: "",
        floorLevel: "",
        noOfWindowsNearSamplingLocation: "",
        curtainsPresent: "",
        mallDistanceFromTrafficSource: "",
        cafeteriaDistanceFromKitchen: "",
        airPurifierUsed: "",
        artificialPlantsPresentInMall: "",
        electronicsUsed: "",
        esclatorsOrLiftInMall: "",
        noOfEsclatorsInMall: "",
        noOfLiftsInMall: "",
        anyIndoorPlantsPresent: "",
        noOfIndoorPlantsPresent: "",
        other_observation: ""
      });
      alert("Observation submitted successfully!");
    } catch (error) {
      console.error("Error submitting observation:", error);
      alert(
        "An error occurred while submitting the observation. Please try again."
      );
    }
  }; 

  return (
    <>
    {/* <Sidebar/> */}
    <ToastContainer /> 
    <form onSubmit={handleSubmit}>  

      <center>
  <h1>Malls</h1></center>
<label>
    Location Id:
    <input
      type="text"
      name="locationId"
      value={observation.locationId}
      onChange={handleChange}
      required
    />
  </label>
  <label>
    Participant Id:
    <input
      type="text"
      name="participantId"
      value={observation.participantId}
      onChange={handleChange}
      required
    />
  </label>
<label>
  Observer Name:
  <input
    type="text"
    name="observerName"
    value={observation.observerName}
    onChange={handleChange}
    required
  />
</label>

<label>
  Date of Observation:
  <input
    type="date"
    name="observationDate"
    value={observation.observationDate}
    onChange={handleChange}
    required
  />
</label>

<label>
  Building Address:
  <input
    type="text"
    name="buildingAddress"
    value={observation.buildingAddress}
    onChange={handleChange}
    required
  />
</label>

<label>
  Building Age (years):
  <select
    name="buildingAgeyears"
    value={observation.buildingAgeyears}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="0-5">0 to 5</option>
    <option value="5-10">5 to 10</option>
    <option value="10-15">10 to 15</option>
    <option value="20+">More than 20</option>
  </select>
</label>
<label>
  New Renovation (less than 3 Months):
  <select
    name="newRenovation"
    value={observation.newRenovation}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>
<label>
  Mall Total Area:
  <select
    name="mallTotalArea"
    value={observation.mallTotalArea}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="<500">Less than 500 m²</option>
    <option value="500-5000">500 to 5000 m²</option>
    <option value=">5000">More than 5000 m²</option>
  </select>
</label>


<label>
 Total number of Occupants:
  <select
    name="occupantsTotal"
    value={observation.occupantsTotal}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="<500">Below 500</option>
    <option value="500-1000">500 to 1000</option>
    <option value="1000-1500">1000 to 1500</option>
    <option value=">1500">More than 1500</option>
  </select>
</label>

<label>
  Number of Shops:
  <select
    name="numberOfShops"
    value={observation.numberOfShops}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="10-50">10 to 50</option>
    <option value="50-100">50 to 100</option>
    <option value="100-250">100 to 250</option>
    <option value=">250">More than 250</option>
  </select>
</label>


<label>
  Food Court and Restaurants Present:
  <select
    name="foodCourtAndRestaurantsPresent"
    value={observation.foodCourtAndRestaurantsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.foodCourtAndRestaurantsPresent === "yes" && (
  <label>
    Type of Cooking Fuel:
    <select
      name="cookingFuel"
      value={observation.cookingFuel}
      onChange={handleChange}
      required
    >
      <option value="">Select an option</option>
      <option value="wood">Wood</option>
      <option value="LPG">LPG</option>
      <option value="electric">Electric</option>
      <option value="coal">Coal</option>
      <option value="others">Others</option>
    </select>
  </label>
)}



<label>
  Ventilation Type:
  <select
    name="ventilationType"
    value={observation.ventilationType}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="window">Window</option>
    <option value="exhaust">Exhaust</option>
    <option value="chimney">Chimney</option>
    <option value="no ventilation">No Ventilation</option>
    <option value="other">Other</option>
  </select>
</label>


<label>
      Ventilation of Whole Mall:
      <select
        name="ventilationOfWholeMall"
        value={observation.ventilationOfWholeMall}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="natural">Natural</option>
        <option value="artificial">Artificial</option>
      </select>
    </label>
    {observation.ventilationOfWholeMall === "natural" && (
      <label>
        Total Number of Windows:
        <select
          name="totalNoOfWindows"
          value={observation.totalNoOfWindows}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="15 to 25">15 to 25</option>
          <option value="25 to 40">25 to 40</option>
          <option value="40 to 60">40 to 60</option>
          <option value=">80">80</option>
        </select>
      </label>
    )}
    {observation.ventilationOfWholeMall === "artificial" && (
  <>
    <label>
      Ducts Maintenance:
      <select
        name="ductsMaintenance"
        value={observation.ductsMaintenance}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="<3 months">&lt;3 months</option>
        <option value="once a year">once a year</option>
        <option value="2 to 3 years">2 to 3 years</option>
        <option value="none">none</option>
      </select>
    </label>
    <label>
      Number of Vents Category:
      <select
        name="noOfVentsCategory"
        value={observation.noOfVentsCategory}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="<50">&lt;50</option>
        <option value="50 to 100">50 to 100</option>
        <option value="100 to 200">100 to 200</option>
        <option value=">500">&gt;500</option>
      </select>
    </label>
    <label>
      Exhaust Fan Inlet Velocity:
      <input
        type="text"
        name="exhaustFanInletVelocity"
        value={observation.exhaustFanInletVelocity}
        onChange={handleChange}
        required
      />
    </label>
    <label>
      Exhaust Fan Outlet Velocity:
      <input
        type="text"
        name="exhaustFanOutletVelocity"
        value={observation.exhaustFanOutletVelocity}
        onChange={handleChange}
        required
      />
    </label>
  </>
)}

    
        <label>
      Cleaning Method:
      <select
        name="cleaningMethod"
        value={observation.cleaningMethod}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="manually">Manually</option>
        <option value="by equipment">By Equipment</option>
      </select>
    </label>
    <label>
      Manual Cleaning Frequency:
      <select
        name="manualCleaningFrequency"
        value={observation.manualCleaningFrequency}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1 to 2">1 to 2</option>
        <option value="2 to 3">2 to 3</option>
        <option value="3 to 4">3 to 4</option>
        <option value=">5">&gt;5</option>
      </select>
    </label>
    <label>
      Equipment Cleaning Frequency:
      <select
        name="equipmentCleaningFrequency"
        value={observation.equipmentCleaningFrequency}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1 to 2">1 to 2</option>
        <option value="2 to 3">2 to 3</option>
        <option value="3 to 4">3 to 4</option>
        <option value=">5">&gt;5</option>
      </select>
    </label>
   
    
    <label>
  Chemical Products Present for Cleaning:
  <select
    name="chemicalProductsPresentForCleaning"
    value={observation.chemicalProductsPresentForCleaning}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.chemicalProductsPresentForCleaning === "yes" && (
  <div>
    <label>
      Chemical Products:
      <select
        name="chemicalProducts"
        value={observation.chemicalProducts}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="detergent">Detergent</option>
        <option value="phenyl">Phenyl</option>
        <option value="bleaching agents">Bleaching Agents</option>
        <option value="toilet cleaners">Toilet Cleaners</option>
        <option value="floor cleaners">Floor Cleaners</option>
        <option value="others">Others</option>
      </select>
    </label>

    {observation.chemicalProducts === "others" && (
      <label>
        Other Chemical Products:
        <input
          type="text"
          name="otherChemicalProducts"
          value={observation.otherChemicalProducts}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </div>
)}

<label>
  Employee Number in Sampling Area:
  <select
    name="employeeNumberInSamplingArea"
    value={observation.employeeNumberInSamplingArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="below3">Below 3</option>
    <option value="3to5">3 to 5</option>
    <option value="5to10">5 to 10</option>
    <option value="moreThan10">More than 10</option>
  </select>
</label>

<label>
  Average Football Each Hour Sampling Area:
  <select
    name="averageFootballEachHourSamplingArea"
    value={observation.averageFootballEachHourSamplingArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<50">&lt;50</option>
    <option value="50-100">50-100</option>
    <option value="100-200">100-200</option>
    <option value="200-300">200-300</option>
    <option value=">300">&gt;300</option>
    <option value="others">Others</option>
  </select>
</label>

{observation.averageFootballEachHourSamplingArea === "others" && (
  <label>
    Other Football Each Hour Sampling Area:
    <input
      type="text"
      name="otherFootballEachHourSamplingArea"
      value={observation.otherFootballEachHourSamplingArea}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Furniture Number in Sampling Area:
  <select
    name="furnitureNumberInSamplingArea"
    value={observation.furnitureNumberInSamplingArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<5">&lt;5</option>
    <option value="5 to 15">5 to 15</option>
    <option value="15 to 30">15 to 30</option>
    <option value=">30">&gt;30</option>
    <option value="others">Others</option>
  </select>
</label>
{observation.furnitureNumberInSamplingArea === "others" && (
  <label>
    Other Furniture Number in Sampling Area:
    <input
      type="text"
      name="otherFurnitureNumberInSamplingArea"
      value={observation.otherFurnitureNumberInSamplingArea}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Furniture Material in Sampling Area:
  <select
    name="furnitureMaterialInSamplingArea"
    value={observation.furnitureMaterialInSamplingArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="plastics">Plastics</option>
    <option value="wood">Wood</option>
    <option value="metals">Metals</option>
    <option value="polished wood">Polished Wood</option>
    <option value="others">Others</option>
  </select>
</label>

<label>
  Wall Material in Sampling Area:
  <select
    name="wallMaterialInSamplingArea"
    value={observation.wallMaterialInSamplingArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="normal paint">Normal Paint</option>
    <option value="tiles">Tiles</option>
    <option value="PVC paneling">PVC Paneling</option>
    <option value="wood paneling">Wood Paneling</option>
    <option value="others">Others</option>
  </select>
</label>

<label>
  Floor Type:
  <select
    name="floorType"
    value={observation.floorType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="normal cemented">Normal Cemented</option>
    <option value="tiles">Tiles</option>
    <option value="carpet">Carpet</option>
    <option value="marble">Marble</option>
    <option value="others">Others</option>
  </select>
</label>

<label>
  Separate Ventilation System in Mall:
  <select
    name="separateVentilationSystemMall"
    value={observation.separateVentilationSystemMall}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Moisture or Damp Patches Marks:
  <select
    name="moistureOrDampPatchesMarks"
    value={observation.moistureOrDampPatchesMarks}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
    <option value="specify">Specify</option>
  </select>
</label>

{observation.moistureOrDampPatchesMarks === "specify" && (
  <label>
    Specify Moisture or Damp Patches Marks:
    <input
      type="text"
      name="specifyMoistureOrDampPatchesMarks"
      value={observation.specifyMoistureOrDampPatchesMarks}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Use of Scented Products:
  <select
    name="useOfScentedProducts"
    value={observation.useOfScentedProducts}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.useOfScentedProducts === "yes" && (
  <label>
    Scented Products:
    <select
      name="scentedProducts"
      value={observation.scentedProducts}
      onChange={handleChange}
      required
    >
      <option value="">Select</option>
      <option value="perfumes">Perfumes</option>
      <option value="fresheners">Fresheners</option>
      <option value="others">Others</option>
    </select>
  </label>
)}

{observation.scentedProducts === "others" && (
  <label>
    Other Scented Products:
    <input
      type="text"
      name="otherScentedProducts"
      value={observation.otherScentedProducts}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Any Separate Temperature Humidity Control System:
  <select
    name="anySeperateTempHumidityControlSystem"
    value={observation.anySeperateTempHumidityControlSystem}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.anySeperateTempHumidityControlSystem === "yes" && (
  <label>
    If Yes, Any Separate Temperature Humidity Control System:
    <input
      type="text"
      name="ifYesAnySeperateTempHumidityControlSystem"
      value={observation.ifYesAnySeperateTempHumidityControlSystem}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Maintenance Frequency:
  <select
    name="maintenanceFrequency"
    value={observation.maintenanceFrequency}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Recently">Recently</option>
    <option value="Once a year">Once a year</option>
    <option value="Every other year">Every other year</option>
    <option value="other">Other</option>
  </select>
</label>

{observation.maintenanceFrequency === "other" && (
  <label>
    Other Maintenance Frequency:
    <input
      type="text"
      name="otherMaintenanceFrequency"
      value={observation.otherMaintenanceFrequency}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Floor Level:
  <select
    name="floorLevel"
    value={observation.floorLevel}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Ground">Ground</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="other">Other</option>
  </select>
</label>
<label>
  Number of Windows Near Sampling Location:
  <select
    name="noOfWindowsNearSamplingLocation"
    value={observation.noOfWindowsNearSamplingLocation}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="0">0</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value=">5">&gt;5</option>
    <option value="other">Other</option>
  </select>
</label>
<label>
  Curtains Present:
  <select
    name="curtainsPresent"
    value={observation.curtainsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Mall Distance From Traffic Source:
  <select
    name="mallDistanceFromTrafficSource"
    value={observation.mallDistanceFromTrafficSource}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="100 to 200">100 to 200</option>
    <option value="200 to 300">200 to 300</option>
    <option value="300 to 400">300 to 400</option>
    <option value="400 to 500">400 to 500</option>
  </select>
</label>

<label>
  Cafeteria Distance From Kitchen:
  <select
    name="cafeteriaDistanceFromKitchen"
    value={observation.cafeteriaDistanceFromKitchen}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="10 to 20">10 to 20</option>
    <option value="20 to 30">20 to 30</option>
    <option value="30 to 40">30 to 40</option>
    <option value="40 to 100">40 to 100</option>
  </select>
</label>

<label>
  Air Purifier Used:
  <select
    name="airPurifierUsed"
    value={observation.airPurifierUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Artificial Plants Present in Mall:
  <select
    name="artificialPlantsPresentInMall"
    value={observation.artificialPlantsPresentInMall}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Electronics Used:
  <select
    name="electronicsUsed"
    value={observation.electronicsUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Escalators or Lifts in Mall:
  <select
    name="esclatorsOrLiftInMall"
    value={observation.esclatorsOrLiftInMall}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.esclatorsOrLiftInMall === "yes" && (
  <>
    <label>
      Number of Escalators in Mall:
      <select
        name="noOfEsclatorsInMall"
        value={observation.noOfEsclatorsInMall}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="2">2</option>
        <option value="4">4</option>
        <option value="6">6</option>
        <option value=">6">More than 6</option>
        <option value="none">None</option>
      </select>
    </label>
    <label>
      Number of Lifts in Mall:
      <select
        name="noOfLiftsInMall"
        value={observation.noOfLiftsInMall}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="2">2</option>
        <option value="4">4</option>
        <option value="6">6</option>
        <option value=">6">More than 6</option>
        <option value="none">None</option>
      </select>
    </label>
  </>
)}

<label>
  Any Indoor Plants Present:
  <select
    name="anyIndoorPlantsPresent"
    value={observation.anyIndoorPlantsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.anyIndoorPlantsPresent === "yes" && (
  <label>
    Number of Indoor Plants Present:
    <select
      name="noOfIndoorPlantsPresent"
      value={observation.noOfIndoorPlantsPresent}
      onChange={handleChange}
      required
    >
      <option value="">Select</option>
      <option value="1 to 3">1 to 3</option>
      <option value="3 to 5">3 to 5</option>
      <option value="5 to 7">5 to 7</option>
      <option value=">10">More than 10</option>
    </select>
  </label>
)}

    <label>
      Other Observation:
      <input
        type="text"
        name="other_observation"
        value={observation.other_observation}
        onChange={handleChange}
        required
      />
    </label>
    <button type="submit">Submit</button>
    </form>

</>
);
};
export default MallForm;
