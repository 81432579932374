import React, { useState } from "react";
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import '../css/common.css';


const OfficeBuilding = () => {
  const [observation, setObservation] = useState({
    buildingId: null,
    locationId: "",
    participantId: "",
    observerName: "",
    observationDate: "",
    officeType: "",
    otherOfficeType: "",
    buildingAddress: "",
    siteLocation: "",
    otherSiteLocation: "",
    distanceFromAreas: "",
    buildingAgeYears: "",
    floorLevel: "",
    otherFloorLevel: "",
    roofCondition: "",
    otherRoofCondition: "",
    gapsBetweenDoorsWindows: "",
    renovationActivities: "",
    renovationTime: "",
    dampMarks: "",
    carpetsPresentOnFloor: "",
    carpetArea: "",
    totalAreaOfficeBuilding: "",
    totalEmployees: "",
    totalAttendance: "",
    totalRooms: "",
    canteenPresent: "",
    cookingFuel: "",
    otherCookingFuel: "",
    cookingTime: "",
    ventilationType: "",
    otherVentilationType: "",
    ventilationTypeInOffice: "",
    noOfWindowsCategory: "",
    ductsMaintenance: "",
    noOfVentsCategory: "",
    exhaustFanInletVelocity: null,
    exhaustFanOutletVelocity: null,
    cleaningMethod: "",
    manualCleaningFrequency: "",
    equipmentCleaningFrequency: "",
    chemicalBasedCleaning: "",
    chemicalProducts: "",
    otherChemicalProducts: "",
    officeGlueUsed: "",
    officePrintersUsed: "",
    printerTypes: "",
    noOfPrinters: "",
    OtherNoOfPrinters: "",
    officeComputersUsed: "",
    computerTypes: "",
    noOfSystems: "",
    officeProjectorUsed: "",
    cabinetryPresent: "",
    noOfDeskChairsCategory: "",
    furnitureMaterial: "",
    otherFurnitureMaterial: "",
    wallOfOffice: "",
    otherWallOfOffice: "",
    floorType: "",
    otherFloorType: "",
    separateVentilationSystem: "",
    moistureOrDampPatches: "",
    moldsPresent: "",
    tempHumidityControlSystem: "",
    lackOfMaintenance: "",
    curtainsPresent: "",
    anyAirPurifierPresent: "",
    anyIndoorPlantPresent: "",
    noOfPlantsPresent: "",
    buildingMaintenance: "",
    buildingMaintenanceIfYes:"",
    smokerInTheBuilding: "",
    anyOtherAcitivity: ""
});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObservation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request with observation data
      const response = await axios.post(
        // "http://localhost:8081/api/officeb/add",
        "http://89.116.20.162:8081/api/officeb/add",

        observation
      );
      console.log(response.data);
      // Reset observation state after successful submission
      setObservation({
        buildingId: null,
        locationId: "",
        participantId: "",
        observerName: "",
        observationDate: "",
        officeType: "",
        otherOfficeType: "",
        buildingAddress: "",
        siteLocation: "",
        otherSiteLocation: "",
        distanceFromAreas: "",
        buildingAgeYears: "",
        floorLevel: "",
        otherFloorLevel: "",
        roofCondition: "",
        otherRoofCondition: "",
        gapsBetweenDoorsWindows: "",
        renovationActivities: "",
        renovationTime: "",
        dampMarks: "",
        carpetsPresentOnFloor: "",
        carpetArea: "",
        totalAreaOfficeBuilding: "",
        totalEmployees: "",
        totalAttendance: "",
        totalRooms: "",
        canteenPresent: "",
        cookingFuel: "",
        otherCookingFuel: "",
        cookingTime: "",
        ventilationType: "",
        otherVentilationType: "",
        ventilationTypeInOffice: "",
        noOfWindowsCategory: "",
        ductsMaintenance: "",
        noOfVentsCategory: "",
        exhaustFanInletVelocity: null,
        exhaustFanOutletVelocity: null,
        cleaningMethod: "",
        manualCleaningFrequency: "",
        equipmentCleaningFrequency: "",
        chemicalBasedCleaning: "",
        chemicalProducts: "",
        otherChemicalProducts: "",
        officeGlueUsed: "",
        officePrintersUsed: "",
        printerTypes: "",
        noOfPrinters: "",
        OtherNoOfPrinters: "",
        officeComputersUsed: "",
        computerTypes: "",
        noOfSystems: "",
        officeProjectorUsed: "",
        cabinetryPresent: "",
        noOfDeskChairsCategory: "",
        furnitureMaterial: "",
        otherFurnitureMaterial: "",
        wallOfOffice: "",
        otherWallOfOffice: "",
        floorType: "",
        otherFloorType: "",
        separateVentilationSystem: "",
        moistureOrDampPatches: "",
        moldsPresent: "",
        tempHumidityControlSystem: "",
        lackOfMaintenance: "",
        curtainsPresent: "",
        anyAirPurifierPresent: "",
        anyIndoorPlantPresent: "",
        noOfPlantsPresent: "",
        buildingMaintenance: "",
        buildingMaintenanceIfYes:"",
        smokerInTheBuilding: "",
        anyOtherAcitivity: ""
      });
      alert("Observation submitted successfully!");
    } catch (error) {
      console.error("Error submitting observation:", error);
      alert(
        "An error occurred while submitting the observation. Please try again."
      );
    }
  }; 

  return (
    <>
    <ToastContainer /> 
    <form onSubmit={handleSubmit}>  

      <center>
  <h1>Office Building</h1></center>
<label>
    Location Id:
    <input
      type="text"
      name="locationId"
      value={observation.locationId}
      onChange={handleChange}
      required
    />
  </label>
  <label>
    Participant Id:
    <input
      type="text"
      name="participantId"
      value={observation.participantId}
      onChange={handleChange}
      required
    />
  </label>
<label>
  Observer Name:
  <input
    type="text"
    name="observerName"
    value={observation.observerName}
    onChange={handleChange}
    required
  />
</label>

<label>
  Date of Observation:
  <input
    type="date"
    name="observationDate"
    value={observation.observationDate}
    onChange={handleChange}
    required
  />
</label>

<label>
  Office Type:
  <select
    name="officeType"
    value={observation.officeType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Bank">Bank</option>
    <option value="Govt. Office">Govt. Office</option>
    <option value="IT Office">IT Office</option>
    <option value="Others">Others</option>
  </select>
</label>

{observation.officeType === "Others" && (
  <label>
    Other Office Type:
    <input
      type="text"
      name="otherOfficeType"
      value={observation.otherOfficeType}
      onChange={handleChange}
      required
    />
  </label>
)}


<label>
  Office Building Address:
  <input
    type="text"
    name="buildingAddress"
    value={observation.buildingAddress}
    onChange={handleChange}
    required
  />
</label>
<label>
  Building Site or Location:
  <select
    name="siteLocation"
    value={observation.siteLocation}
    onChange={handleChange}
    required
  >
    <option value="">Select...</option>
    <option value="Bank">Bank</option>
    <option value="Govt.Office">Govt. Office</option>
    <option value="IT Office">IT Office</option>
    <option value="others">Others</option>
  </select>
</label>

{observation.siteLocation === "others" && (
  <label>
  Other Site Location:
  <input
    type="text"
    name="otherSiteLocation"
    value={observation.otherSiteLocation}
    onChange={handleChange}
    required
  />
</label>
)}


<label>
  Distance From Areas:
  <select
    name="distanceFromAreas"
    value={observation.distanceFromAreas}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="10 to 100">10 to 100</option>
    <option value="100 to 200">100 to 200</option>
    <option value="200 to 300">200 to 300</option>
    <option value="300 to 400">300 to 400</option>
    <option value=">500">more than 500</option>
  </select>
</label>



<label>
  Building Age (years):
  <select
    name="buildingAgeYears"
    value={observation.buildingAgeYears}
    onChange={handleChange}
    required
  >
    <option value="">Select an option</option>
    <option value="0-5">0 to 5</option>
    <option value="5-10">5 to 10</option>
    <option value="10-20">10 to 20</option>
    <option value="20-30">20 to 30</option>
    <option value="30+">More than 30</option>
  </select>
</label>
<label>
  Floor Level:
  <select
    name="floorLevel"
    value={observation.floorLevel}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Ground">Ground</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="other">Other</option>
  </select>
</label>

{observation.floorLevel === "other" && (
  <label>
    Other Floor Level:
    <input
      type="text"
      name="otherFloorLevel"
      value={observation.otherFloorLevel}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Roof Condition:
  <select
    name="roofCondition"
    value={observation.roofCondition}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Damaged">Damaged</option>
    <option value="False ceiling">False ceiling</option>
    <option value="New Repair">New Repair</option>
    <option value="Cracks">Cracks</option>
    <option value="other">Others</option>
  </select>
</label>

{observation.roofCondition === "other" && (
  <label>
    Other Roof Condition:
    <input
      type="text"
      name="otherRoofCondition"
      value={observation.otherRoofCondition}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Gaps Between Doors/Windows:
  <select
    name="gapsBetweenDoorsWindows"
    value={observation.gapsBetweenDoorsWindows}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>



<label>
  Renovation Activities:
  <select
    name="renovationActivities"
    value={observation.renovationActivities}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

{observation.renovationActivities === "Yes" && (
  <label>
    Renovation Time:
    <select
      name="renovationTime"
      value={observation.renovationTime}
      onChange={handleChange}
      required
    >
      <option value="">Select</option>
      <option value="<1 year">&lt;1 year</option>
      <option value=">1 year">&gt;1 year</option>
      <option value=">2 years">&gt;2 years</option>
      <option value=">3 years">&gt;3 years</option>
    </select>
  </label>
)}

<label>
  Moisture or Damp Patches:
  <select
    name="dampMarks"
    value={observation.dampMarks}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Carpets Present on Floor:
  <select
    name="carpetsPresentOnFloor"
    value={observation.carpetsPresentOnFloor}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

{observation.carpetsPresentOnFloor === "Yes" && (
  <label>
    Carpet Area in Foots:
    <select
      name="carpetArea"
      value={observation.carpetArea}
      onChange={handleChange}
      required
    >
      <option value="">Select</option>
      <option value="Below 20ft2">Below 20ft²</option>
      <option value="20 To 40ft2">20 To 40ft²</option>
      <option value="40ft2 To 60ft2">40ft² To 60ft²</option>
      <option value="60ft2 To 80ftr2">60ft² To 80ft²</option>
    </select>
  </label>
)}


<label>
  Total Area of Office Building:
  <select
    name="totalAreaOfficeBuilding"
    value={observation.totalAreaOfficeBuilding}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Below 200m2">Below 200m2</option>
    <option value="200 To 400m2">200 To 400m2</option>
    <option value="400m2 To 600m2">400m2 To 600m2</option>
    <option value=">600m2">Greater than 600m2</option>
  </select>
</label>


<label>
  Total Number of Employees:
  <select
    name="totalEmployees"
    value={observation.totalEmployees}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<50">Less than 50</option>
    <option value="50 to 100">50 to 100</option>
    <option value="100 to 200">100 to 200</option>
    <option value="200 to 300">200 to 300</option>
    <option value=">300">Greater than 300</option>
  </select>
</label>

<label>
  Total Attendance of Employees in a Year:
  <select
    name="totalAttendance"
    value={observation.totalAttendance}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="10-30%">10-30%</option>
    <option value="30-50%">30-50%</option>
    <option value="50-70%">50-70%</option>
    <option value="70-90%">70-90%</option>
    <option value=">90%">Greater than 90%</option>
  </select>
</label>

<label>
  Total Number of Rooms in Office:
  <select
    name="totalRooms"
    value={observation.totalRooms}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1-5">1-5</option>
    <option value="5-10">5-10</option>
    <option value="10-15">10-15</option>
    <option value="15-20">15-20</option>
    <option value=">20">Greater than 20</option>
  </select>
</label>




<label>
  Canteen Present:
  <select
    name="canteenPresent"
    value={observation.canteenPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

{observation.canteenPresent === "Yes" && (
  <div>
 <label>
  Cooking Fuel:
  <select
    name="cookingFuel"
    value={observation.cookingFuel}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Wood">Wood</option>
    <option value="Log">Log</option>
    <option value="Electric">Electric</option>
    <option value="Coal">Coal</option>
    <option value="Other">Others</option>
  </select>
</label>


    {observation.cookingFuel === "Other" && (
      <label>
        Other Cooking Fuel:
        <input
          type="text"
          name="otherCookingFuel"
          value={observation.otherCookingFuel}
          onChange={handleChange}
          required
        />
      </label>
    )}

<label>
  Cooking Time (in hours):
  <select
    name="cookingTime"
    value={observation.cookingTime}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1-2">1-2 hours</option>
    <option value="2-3">2-3 hours</option>
    <option value="3-4">3-4 hours</option>
    <option value="4-5">4-5 hours</option>
    <option value=">5">More than 5 hours</option>
  </select>
</label>


<label>
  Ventilation Type:
  <select
    name="ventilationType"
    value={observation.ventilationType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Window">Window</option>
    <option value="Exhaust">Exhaust</option>
    <option value="Chimney">Chimney</option>
    <option value="No Ventilation">No Ventilation</option>
    <option value="Other">Other</option>
  </select>
</label>


    {observation.ventilationType === "Other" && (
      <label>
        Other Ventilation Type:
        <input
          type="text"
          name="otherVentilationType"
          value={observation.otherVentilationType}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </div>
)}
{observation.canteenPresent === "No" && (
  <>
    <input type="hidden" name="cookingFuel" value="" />
    <input type="hidden" name="cookingTime" value="" />
    <input type="hidden" name="ventilationType" value="" />
    <input type="hidden" name="otherCookingFuel" value="" />
    <input type="hidden" name="otherVentilationType" value="" />
  </>
)}







<label>
      Ventilation type in the office:
      <select
        name="ventilationTypeInOffice"
        value={observation.ventilationTypeInOffice}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="natural">Natural</option>
        <option value="artificial">Artificial</option>
      </select>
    </label>
    {observation.ventilationTypeInOffice === "natural" && (
      <label>
        Total Number of Windows:
        <select
          name="noOfWindowsCategory"
          value={observation.noOfWindowsCategory}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="15 to 25">15 to 25</option>
          <option value="25 to 40">25 to 40</option>
          <option value="40 to 60">40 to 60</option>
          <option value=">80">80</option>
        </select>
      </label>
    )}
    {observation.ventilationTypeInOffice === "artificial" && (
  <>
    <label>
      Ducts Maintenance:
      <select
        name="ductsMaintenance"
        value={observation.ductsMaintenance}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="<3 months">&lt;3 months</option>
        <option value="once a year">once a year</option>
        <option value="2 to 3 years">2 to 3 years</option>
        <option value="none">none</option>
      </select>
    </label>
    <label>
      Number of Vents Category:
      <select
        name="noOfVentsCategory"
        value={observation.noOfVentsCategory}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="<50">&lt;50</option>
        <option value="50 to 100">50 to 100</option>
        <option value="100 to 200">100 to 200</option>
        <option value=">500">&gt;500</option>
      </select>
    </label>
    <label>
      Exhaust Fan Inlet Velocity:
      <input
        type="text"
        name="exhaustFanInletVelocity"
        value={observation.exhaustFanInletVelocity}
        onChange={handleChange}
        required
      />
    </label>
    <label>
      Exhaust Fan Outlet Velocity:
      <input
        type="text"
        name="exhaustFanOutletVelocity"
        value={observation.exhaustFanOutletVelocity}
        onChange={handleChange}
        required
      />
    </label>
  </>
)}

    
        <label>
      Cleaning Method:
      <select
        name="cleaningMethod"
        value={observation.cleaningMethod}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="manually">Manually</option>
        <option value="by equipment">By Equipment</option>
      </select>
    </label>
    <label>
      Manual Cleaning Frequency:
      <select
        name="manualCleaningFrequency"
        value={observation.manualCleaningFrequency}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1 to 2">1 to 2</option>
        <option value="2 to 3">2 to 3</option>
        <option value="3 to 4">3 to 4</option>
        <option value="none">none</option>
      </select>
    </label>
    <label>
      Equipment Cleaning Frequency:
      <select
        name="equipmentCleaningFrequency"
        value={observation.equipmentCleaningFrequency}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1 to 2">1 to 2</option>
        <option value="2 to 3">2 to 3</option>
        <option value="3 to 4">3 to 4</option>
        <option value="none">none</option>
      </select>
    </label>
   
    
    <label>
  Chemical Products Present for Cleaning:
  <select
    name="chemicalBasedCleaning"
    value={observation.chemicalBasedCleaning}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.chemicalBasedCleaning === "yes" && (
  <div>
    <label>
      Chemical Products:
      <select
        name="chemicalProducts"
        value={observation.chemicalProducts}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="detergent">Detergent</option>
        <option value="phenyl">Phenyl</option>
        <option value="bleaching agents">Bleaching Agents</option>
        <option value="toilet cleaners">Toilet Cleaners</option>
        <option value="floor cleaners">Floor Cleaners</option>
        <option value="others">Others</option>
      </select>
    </label>

    {observation.chemicalProducts === "others" && (
      <label>
        Other Chemical Products:
        <input
          type="text"
          name="otherChemicalProducts"
          value={observation.otherChemicalProducts}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </div>
)}

<label>
  Office Glue Used:
  <select
    name="officeGlueUsed"
    value={observation.officeGlueUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>


    <label>
      Office Printers Used:
      <select
        name="officePrintersUsed"
        value={observation.officePrintersUsed}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    </label>

    {observation.officePrintersUsed === "Yes" && (
      <>
       <label>
  Printer Types:
  <select
    name="printerTypes"
    value={observation.printerTypes}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Inkjet">Inkjet</option>
    <option value="Lazer Printer">Lazer Printer</option>
    <option value="Both">Both</option>
  </select>
</label>

<label>
  No. of Printers:
  <select
    name="noOfPrinters"
    value={observation.noOfPrinters}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1-3">1-3</option>
    <option value="3-6">3-6</option>
    <option value="6-9">6-9</option>
    <option value="Other">Other</option>
  </select>
</label>
{observation.noOfPrinters === "Other" && (
  <label>
    Other No. of Printers:
    <input
      type="text"
      name="otherNoOfPrinters"
      value={observation.otherNoOfPrinters}
      onChange={handleChange}
      required
    />
  </label>
)}

      </>
    )}
  


<label>
  Office Computers Used:
  <select
    name="officeComputersUsed"
    value={observation.officeComputersUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

{observation.officeComputersUsed === "Yes" && (
  <>
    <label>
      Computer Types:
      <select
        name="computerTypes"
        value={observation.computerTypes}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="Desktops">Desktops</option>
        <option value="Laptops">Laptops</option>
        <option value="Both">Both</option>
      </select>
    </label>
    <label>
      No. of Systems:
      <select
        name="noOfSystems"
        value={observation.noOfSystems}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1-10">1-10</option>
        <option value="10-20">10-20</option>
        <option value="20-30">20-30</option>
        <option value="30-40">30-40</option>
        <option value=">50">50</option>
      </select>
    </label>
  </>
)}

<label>
  Office Projector Used:
  <select
    name="officeProjectorUsed"
    value={observation.officeProjectorUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Cabinetry Present:
  <select
    name="cabinetryPresent"
    value={observation.cabinetryPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  No. of Desk Chairs Category:
  <select
    name="noOfDeskChairsCategory"
    value={observation.noOfDeskChairsCategory}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<10">Less than 10</option>
    <option value="10-30">10-30</option>
    <option value="30-60">30-60</option>
    <option value="60-90">60-90</option>
    <option value=">100">Greater than 100</option>
  </select>
</label>


<label>
  Furniture Material:
  <select
    name="furnitureMaterial"
    value={observation.furnitureMaterial}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Plastics">Plastics</option>
    <option value="Wood">Wood</option>
    <option value="Metals">Metals</option>
    <option value="Polished Wood">Polished Wood</option>
    <option value="Other">Others</option>
  </select>
</label>

{observation.furnitureMaterial === "Other" && (
  <label>
    Other Furniture Material:
    <input
      type="text"
      name="otherFurnitureMaterial"
      value={observation.otherFurnitureMaterial}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Wall of Office:
  <select
    name="wallOfOffice"
    value={observation.wallOfOffice}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Normal Paint">Normal Paint</option>
    <option value="Tiles">Tiles</option>
    <option value="PVC Paneling">PVC Paneling</option>
    <option value="Wood Paneling">Wood Paneling</option>
    <option value="Other">Others</option>
  </select>
</label>

{observation.wallOfOffice === "Other" && (
  <label>
    Other Wall of Office:
    <input
      type="text"
      name="otherWallOfOffice"
      value={observation.otherWallOfOffice}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Floor Type:
  <select
    name="floorType"
    value={observation.floorType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Normal cemented">Normal cemented</option>
    <option value="Tiles">Tiles</option>
    <option value="Carpet">Carpet</option>
    <option value="Marble">Marble</option>
    <option value="Other">Others</option>
  </select>
</label>

{observation.floorType === "Other" && (
  <label>
    Other Floor Type:
    <input
      type="text"
      name="otherFloorType"
      value={observation.otherFloorType}
      onChange={handleChange}
      required
    />
  </label>
)}
<label>
  Separate Ventilation System:
  <select
    name="separateVentilationSystem"
    value={observation.separateVentilationSystem}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Moisture or Damp Patches:
  <select
    name="moistureOrDampPatches"
    value={observation.moistureOrDampPatches}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Molds Present:
  <select
    name="moldsPresent"
    value={observation.moldsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Temp Humidity Control System:
  <select
    name="tempHumidityControlSystem"
    value={observation.tempHumidityControlSystem}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Lack of Maintenance:
  <select
    name="lackOfMaintenance"
    value={observation.lackOfMaintenance}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Curtains Present:
  <select
    name="curtainsPresent"
    value={observation.curtainsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Any Air Purifier Present:
  <select
    name="anyAirPurifierPresent"
    value={observation.anyAirPurifierPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

<label>
  Any Indoor Plants Present:
  <select
    name="anyIndoorPlantsPresent"
    value={observation.anyIndoorPlantsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.anyIndoorPlantsPresent === "yes" && (
  <label>
    Number of Indoor Plants Present:
    <select
      name="noOfPlantsPresent"
      value={observation.noOfPlantsPresent}
      onChange={handleChange}
      required
    >
      <option value="">Select</option>
      <option value="1 to 3">1 to 3</option>
      <option value="3 to 5">3 to 5</option>
      <option value="5 to 7">5 to 7</option>
      <option value=">10">More than 10</option>
    </select>
  </label>
)}

<label>
  Building Maintenance:
  <select
    name="buildingMaintenance"
    value={observation.buildingMaintenance}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</label>

{observation.buildingMaintenance === "Yes" && (
  <label>
    Building Maintenance If Yes:
    <select
      name="buildingMaintenanceIfYes"
      value={observation.buildingMaintenanceIfYes}
      onChange={handleChange}
      required
    >
      <option value="">Select</option>
      <option value="1-3">1-3</option>
      <option value="3-6">3-6</option>
      <option value="6-10">6-10</option>
      <option value=">10">greater than 10</option>
      <option value="None">None</option>
    </select>
  </label>
)}

<label>
  Smoker in the Building:
  <select
    name="smokerInTheBuilding"
    value={observation.smokerInTheBuilding}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1-3">1-3</option>
    <option value="3-6">3-6</option>
    <option value="6-10">6-10</option>
    <option value=">10">&gt;10</option>
    <option value="None">None</option>
  </select>
</label>


<label>
  Any other activities observed during sampling:
  <input
    type="text"
    name="anyOtherAcitivity"
    value={observation.anyOtherAcitivity}
    onChange={handleChange}
    required
  />
</label>
<button type="submit">Submit</button>

</form>
</>
);
};
export default OfficeBuilding;
