import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for leaflet default icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Custom component to adjust the map view
const MapViewAdjuster = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [bounds, map]);

  return null;
};

const MapGraph = ({ locations = [] }) => {
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (locations.length > 0) {
      const latitudes = locations.map(location => parseFloat(location.coordinates.split(',')[0]));
      const longitudes = locations.map(location => parseFloat(location.coordinates.split(',')[1]));

      const minLat = Math.min(...latitudes);
      const maxLat = Math.max(...latitudes);
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);

      const newBounds = [
        [minLat, minLng],
        [maxLat, maxLng],
      ];
      setBounds(newBounds);
    } else {
      setBounds([
        [8.4, 68.1], // Southwest coordinates of India
        [37.6, 97.4], // Northeast coordinates of India
      ]);
    }
  }, [locations]);

  return (
    <MapContainer
      center={[20.5937, 78.9629]} // Center for India
      zoom={5} // Default zoom level to show India
      style={{ height: "100%", width: "100%" }}
      zoomControl={false} // Disables the zoom control buttons
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapViewAdjuster bounds={bounds} />
      {locations.map(location => {
        const [lat, lng] = location.coordinates.split(',').map(Number);
        return (
          <Marker key={location.id} position={[lat, lng]}>
            <Popup>
              <h3>{location.subCategory}</h3>
              <p><strong>Location ID:</strong> {location.locationId}</p>
              <p><strong>Category:</strong> {location.category}</p>
              <p><strong>Criteria:</strong> {location.criteria}</p>
              <p><strong>Status:</strong> {location.status}</p>
              <p><strong>Google Map Link:</strong> <a href={location.googleMapLink} target="_blank" rel="noopener noreferrer">View Map</a></p>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapGraph;
