import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import '../css/TigerxtData.css';

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const TigerxtData = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        locationId: 'Lall', // Default filter
        category: '',
        subCategory: '',
        criteria: '',
        season: ''
    });
    const [filterOptions, setFilterOptions] = useState({
        locationIds: [],
        categories: [],
        subCategories: [],
        criteria: [],
        seasons: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [minTvoc, setMinTvoc] = useState(null);
    const [maxTvoc, setMaxTvoc] = useState(null);
    const [filtersVisible, setFiltersVisible] = useState(false); // State for filter visibility

    useEffect(() => {
        // Fetch filter options
        axios.get('http://89.116.20.162:8081/api/tigerxt/filters/locationIds')
        // axios.get('http://localhost:8081/api/tigerxt/filters/locationIds')

            .then(response => setFilterOptions(prev => ({ ...prev, locationIds: response.data })))
            .catch(err => setError('Failed to fetch location IDs'));

        axios.get('http://89.116.20.162:8081/api/tigerxt/filters/categories')
        // axios.get('http://localhost:8081/api/tigerxt/filters/categories')

            .then(response => setFilterOptions(prev => ({ ...prev, categories: response.data })))
            .catch(err => setError('Failed to fetch categories'));

        axios.get('http://89.116.20.162:8081/api/tigerxt/filters/subCategories')
        // axios.get('http://localhost:8081/api/tigerxt/filters/subCategories')

            .then(response => setFilterOptions(prev => ({ ...prev, subCategories: response.data })))
            .catch(err => setError('Failed to fetch subCategories'));

        axios.get('http://89.116.20.162:8081/api/tigerxt/filters/criteria')
        // axios.get('http://localhost:8081/api/tigerxt/filters/criteria')

            .then(response => setFilterOptions(prev => ({ ...prev, criteria: response.data })))
            .catch(err => setError('Failed to fetch criteria'));

        axios.get('http://89.116.20.162:8081/api/tigerxt/filters/seasons')
        // axios.get('http://localhost:8081/api/tigerxt/filters/seasons')

            .then(response => setFilterOptions(prev => ({ ...prev, seasons: response.data })))
            .catch(err => setError('Failed to fetch seasons'));

        // Initial data fetch with default filters
        fetchData(filters);
    }, []);

    useEffect(() => {
        // Fetch filtered data whenever filters change
        fetchData(filters);
    }, [filters]);

    const fetchData = (filters) => {
        setLoading(true);
        axios.get('http://89.116.20.162:8081/api/tigerxt/data', { params: filters })
        // axios.get('http://localhost:8081/api/tigerxt/data', { params: filters })

            .then(response => {
                const data = response.data;
                setData(data);
                setLoading(false);

                // Calculate min and max TVOC values
                if (data.length > 0) {
                    const tvocValues = data.map(item => item.tvoc);
                    setMinTvoc(Math.min(...tvocValues));
                    setMaxTvoc(Math.max(...tvocValues));
                } else {
                    setMinTvoc(null);
                    setMaxTvoc(null);
                }
            })
            .catch(err => {
                setError('Failed to fetch data');
                setLoading(false);
            });
    };

    const handleFilterChange = (e) => {
        setFilters(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const chartData = {
        labels: data.map(item => new Date(item.dateTime).toLocaleDateString()), // Format the date
        datasets: [
            {
                label: 'TVOC (ppm)', // Custom label for Y-axis
                data: data.map(item => item.tvoc),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false
            }
        ]
    };

    const chartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date' // X-axis label
                },
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'TVOC (ppm)' // Y-axis label
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => `${value} ppm` // Format the y-axis labels
                }
            }
        }
    };

    return (
        <div>
            <h2>Volatile Organic Carbon (ppm)</h2>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <button className="dropdown-toggle" onClick={() => setFiltersVisible(!filtersVisible)}>
                        {filtersVisible ? 'Hide Filters' : 'Show Filters'}
                    </button>

                    {filtersVisible && (
                        <>
                            <div>
                                <select name="locationId" value={filters.locationId} onChange={handleFilterChange}>
                                    <option value="Lall">All Locations</option>
                                    {filterOptions.locationIds.map(id => (
                                        <option key={id} value={id}>{id}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select name="category" value={filters.category} onChange={handleFilterChange}>
                                    <option value="">All Categories</option>
                                    {filterOptions.categories.map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select name="subCategory" value={filters.subCategory} onChange={handleFilterChange}>
                                    <option value="">All SubCategories</option>
                                    {filterOptions.subCategories.map(sub => (
                                        <option key={sub} value={sub}>{sub}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select name="criteria" value={filters.criteria} onChange={handleFilterChange}>
                                    <option value="">All Criteria</option>
                                    {filterOptions.criteria.map(crit => (
                                        <option key={crit} value={crit}>{crit}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select name="season" value={filters.season} onChange={handleFilterChange}>
                                    <option value="">All Seasons</option>
                                    {filterOptions.seasons.map(season => (
                                        <option key={season} value={season}>{season}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}

                    <Line data={chartData} options={chartOptions} />

                    <div className="tvoc-statistics-container">
                        <h3>TVOC Statistics</h3>
                        {minTvoc !== null && maxTvoc !== null ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Statistic</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Min TVOC</td>
                                        <td>{minTvoc} ppm</td>
                                    </tr>
                                    <tr>
                                        <td>Max TVOC</td>
                                        <td>{maxTvoc} ppm</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <p>No data available for TVOC statistics.</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default TigerxtData;
