import React, { useState, useEffect } from "react";
import axios from "axios";
import ApexCharts from "react-apexcharts";
import ".././components/css/LungOscillometryChart.css";

const LungOscillometryChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "http://89.116.20.162:8081/api/participants/lung-oscillometry"
      );

      const data = response.data;
      setData([
        { category: "Performed", count: data.performedCount },
        { category: "Not Performed", count: data.notPerformedCount },
      ]);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data: " + error.message);
      setLoading(false);
    }
  };

  const options = {
    chart: {
      type: 'bar',
      height: 400,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => `${value}`,
      offsetY: -20
    },
    xaxis: {
      categories: data.map(item => item.category),
      title: {
        text: 'Category'
      }
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      colors: ['#FF4560']
    },
    tooltip: {
      y: {
        formatter: (value) => `${value}`
      }
    }
  };

  const series = [
    {
      name: 'Count',
      data: data.map(item => item.count)
    }
  ];

  if (loading) return <div className="loader">Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div
      style={{
        maxWidth: "700px",
        margin: "20px auto",
        padding: "20px",
        boxSizing: "border-box",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
      className="lung-oscillometry-container"
    >
      <h2
        style={{
          textAlign: "center",
          paddingBottom: "16px",
          paddingTop: "5px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        Lung Oscillometry Counts
      </h2>
      <div>
        <ApexCharts options={options} series={series} type="bar" height={400} />
      </div>
    </div>
  );
};

export default LungOscillometryChart;
