import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import "../css/GrimmsData.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const GrimmsData = () => {
  const [data, setData] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [seasons, setSeasons] = useState([]);

  // Set 'Lall' as the default location ID
  const [selectedLocationId, setSelectedLocationId] = useState("Lall");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");

  const [maxPm1, setMaxPm1] = useState(null);
  const [maxPm2_5, setMaxPm2_5] = useState(null);
  const [maxPm10, setMaxPm10] = useState(null);
  const [minPm1, setMinPm1] = useState(null);
  const [minPm2_5, setMinPm2_5] = useState(null);
  const [minPm10, setMinPm10] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    selectedLocationId,
    selectedCategory,
    selectedSubCategory,
    selectedCriteria,
    selectedSeason,
  ]);

  const fetchFilters = async () => {
    try {
      const [
        locationResponse,
        categoryResponse,
        subCategoryResponse,
        criteriaResponse,
        seasonResponse,
      ] = await Promise.all([
        axios.get("http://89.116.20.162:8081/api/grimms/locations"),
        axios.get("http://89.116.20.162:8081/api/grimms/categories"),
        axios.get("http://89.116.20.162:8081/api/grimms/subcategories"),
        axios.get("http://89.116.20.162:8081/api/grimms/criteria"),
        axios.get("http://89.116.20.162:8081/api/grimms/seasons"),


        // axios.get("http://localhost:8081/api/grimms/locations"),
        // axios.get("http://localhost:8081/api/grimms/categories"),
        // axios.get("http://localhost:8081/api/grimms/subcategories"),
        // axios.get("http://localhost:8081/api/grimms/criteria"),
        // axios.get("http://localhost:8081/api/grimms/seasons"),
      ]);

      setLocationIds(locationResponse.data);
      setCategories(categoryResponse.data);
      setSubCategories(subCategoryResponse.data);
      setCriteriaList(criteriaResponse.data);
      setSeasons(seasonResponse.data);
    } catch (error) {
      console.error("Error fetching filter data:", error);
      setError("Failed to fetch filter data");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError("");
    try {

      // let endpoint = "http://localhost:8081/api/grimms/filtered";

      let endpoint = "http://89.116.20.162:8081/api/grimms/filtered";

      const params = new URLSearchParams();

      if (selectedLocationId) params.append("locationId", selectedLocationId);
      if (selectedCategory) params.append("category", selectedCategory);
      if (selectedSubCategory) params.append("subCategory", selectedSubCategory);
      if (selectedCriteria) params.append("criteria", selectedCriteria);
      if (selectedSeason) params.append("season", selectedSeason);

      const response = await axios.get(endpoint, { params });
      setData(response.data);
      calculateMaxMinValues(response.data);
    } catch (error) {
      console.error("Error fetching Grimms data:", error);
      setError("Failed to fetch Grimms data");
    } finally {
      setLoading(false);
    }
  };

  const calculateMaxMinValues = (data) => {
    if (data.length > 0) {
      const pm1Values = data.map((entry) => entry.pm1);
      const pm2_5Values = data.map((entry) => entry.pm2_5);
      const pm10Values = data.map((entry) => entry.pm10);

      setMaxPm1(Math.round(Math.max(...pm1Values)));
      setMaxPm2_5(Math.round(Math.max(...pm2_5Values)));
      setMaxPm10(Math.round(Math.max(...pm10Values)));

      setMinPm1(Math.round(Math.min(...pm1Values)));
      setMinPm2_5(Math.round(Math.min(...pm2_5Values)));
      setMinPm10(Math.round(Math.min(...pm10Values)));
    } else {
      setMaxPm1(null);
      setMaxPm2_5(null);
      setMaxPm10(null);
      setMinPm1(null);
      setMinPm2_5(null);
      setMinPm10(null);
    }
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const chartData = {
    labels: data.map((entry) => entry.dateTime),
    datasets: [
      {
        label: "PM1",
        data: data.map((entry) => entry.pm1),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: false,
      },
      {
        label: "PM2.5",
        data: data.map((entry) => entry.pm2_5),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: false,
      },
      {
        label: "PM10",
        data: data.map((entry) => entry.pm10),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: false,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Value: ${context.raw.toFixed(1)}`;
          },
          title: (tooltipItems) => {
            const date = new Date(tooltipItems[0].label);
            return date.toLocaleString();
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date-time",
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Particulate Matter (µg/m3)",
        },
        ticks: {
          callback: (value) => value.toFixed(1),
        },
        grid: {
          display: false,
        },
      },
    },
    aspectRatio: 1.5, // Adjust aspect ratio as needed
  };
  
  

  return (
    <div className="grimms-container">
      <h2 className="grimms-heading">Particulate Matter (µg/m3)</h2>
      {error && <p className="error-message">{error}</p>}

      <div className="filters-dropdown">
        <button
          className="dropdown-toggle"
          onClick={() => setFiltersVisible(!filtersVisible)}
        >
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </button>

        {filtersVisible && (
          <div className="filters-content">
            <div className="filter-group">
              <select
                id="locationSelect"
                onChange={handleFilterChange(setSelectedLocationId)}
                value={selectedLocationId}
              >
                <option value="Lall">All Locations</option>
                {locationIds.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="categorySelect"
                onChange={handleFilterChange(setSelectedCategory)}
                value={selectedCategory}
              >
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="subCategorySelect"
                onChange={handleFilterChange(setSelectedSubCategory)}
                value={selectedSubCategory}
              >
                <option value="">All Sub-Categories</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="criteriaSelect"
                onChange={handleFilterChange(setSelectedCriteria)}
                value={selectedCriteria}
              >
                <option value="">All Criteria</option>
                {criteriaList.map((criteria) => (
                  <option key={criteria} value={criteria}>
                    {criteria}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="seasonSelect"
                onChange={handleFilterChange(setSelectedSeason)}
                value={selectedSeason}
              >
                <option value="">All Seasons</option>
                {seasons.map((season) => (
                  <option key={season} value={season}>
                    {season}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <p>Loading data...</p>
      ) : (
        <Line data={chartData} options={chartOptions} />
      )}

      <div className="max-min-summary">
        <h3>Statistics</h3>
        <table className="summary-table">
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Max</th>
              <th>Min</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PM1</td>
              <td>{maxPm1 !== null ? maxPm1 : "N/A"}</td>
              <td>{minPm1 !== null ? minPm1 : "N/A"}</td>
            </tr>
            <tr>
              <td>PM2.5</td>
              <td>{maxPm2_5 !== null ? maxPm2_5 : "N/A"}</td>
              <td>{minPm2_5 !== null ? minPm2_5 : "N/A"}</td>
            </tr>
            <tr>
              <td>PM10</td>
              <td>{maxPm10 !== null ? maxPm10 : "N/A"}</td>
              <td>{minPm10 !== null ? minPm10 : "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GrimmsData;

/*

// Apex charts 

import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import "../css/GrimmsData.css";

const GrimmsData = () => {
  const [data, setData] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const [selectedLocationId, setSelectedLocationId] = useState("Lall");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");

  const [maxPm1, setMaxPm1] = useState(null);
  const [maxPm2_5, setMaxPm2_5] = useState(null);
  const [maxPm10, setMaxPm10] = useState(null);
  const [minPm1, setMinPm1] = useState(null);
  const [minPm2_5, setMinPm2_5] = useState(null);
  const [minPm10, setMinPm10] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    selectedLocationId,
    selectedCategory,
    selectedSubCategory,
    selectedCriteria,
    selectedSeason,
  ]);

  const fetchFilters = async () => {
    try {
      const [
        locationResponse,
        categoryResponse,
        subCategoryResponse,
        criteriaResponse,
        seasonResponse,
      ] = await Promise.all([
        axios.get("http://localhost:8081/api/grimms/locations"),
        axios.get("http://localhost:8081/api/grimms/categories"),
        axios.get("http://localhost:8081/api/grimms/subcategories"),
        axios.get("http://localhost:8081/api/grimms/criteria"),
        axios.get("http://localhost:8081/api/grimms/seasons"),
      ]);

      setLocationIds(locationResponse.data);
      setCategories(categoryResponse.data);
      setSubCategories(subCategoryResponse.data);
      setCriteriaList(criteriaResponse.data);
      setSeasons(seasonResponse.data);
    } catch (error) {
      console.error("Error fetching filter data:", error);
      setError("Failed to fetch filter data");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError("");
    try {
      let endpoint = "http://localhost:8081/api/grimms/filtered";
      const params = new URLSearchParams();

      if (selectedLocationId) params.append("locationId", selectedLocationId);
      if (selectedCategory) params.append("category", selectedCategory);
      if (selectedSubCategory) params.append("subCategory", selectedSubCategory);
      if (selectedCriteria) params.append("criteria", selectedCriteria);
      if (selectedSeason) params.append("season", selectedSeason);

      const response = await axios.get(endpoint, { params });
      setData(response.data);
      calculateMaxMinValues(response.data);
    } catch (error) {
      console.error("Error fetching Grimms data:", error);
      setError("Failed to fetch Grimms data");
    } finally {
      setLoading(false);
    }
  };

  const calculateMaxMinValues = (data) => {
    if (data.length > 0) {
      const pm1Values = data.map((entry) => entry.pm1);
      const pm2_5Values = data.map((entry) => entry.pm2_5);
      const pm10Values = data.map((entry) => entry.pm10);

      setMaxPm1(Math.round(Math.max(...pm1Values)));
      setMaxPm2_5(Math.round(Math.max(...pm2_5Values)));
      setMaxPm10(Math.round(Math.max(...pm10Values)));

      setMinPm1(Math.round(Math.min(...pm1Values)));
      setMinPm2_5(Math.round(Math.min(...pm2_5Values)));
      setMinPm10(Math.round(Math.min(...pm10Values)));
    } else {
      setMaxPm1(null);
      setMaxPm2_5(null);
      setMaxPm10(null);
      setMinPm1(null);
      setMinPm2_5(null);
      setMinPm10(null);
    }
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Particulate Matter (µg/m3)",
      align: "left",
    },
    xaxis: {
      categories: data.map((entry) => entry.dateTime),
      title: {
        text: "Date-time",
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "Particulate Matter (µg/m3)",
      },
    },
    tooltip: {
      shared: true,
      x: {
        format: "dd MMM yyyy HH:mm",
      },
      y: {
        formatter: function (val) {
          return `${val.toFixed(1)} µg/m3`;
        },
      },
    },
  };

  const chartSeries = [
    {
      name: "PM1",
      data: data.map((entry) => entry.pm1),
    },
    {
      name: "PM2.5",
      data: data.map((entry) => entry.pm2_5),
    },
    {
      name: "PM10",
      data: data.map((entry) => entry.pm10),
    },
  ];

  return (
    <div className="grimms-container">
      <h2 className="grimms-heading">Particulate Matter (µg/m3)</h2>
      {error && <p className="error-message">{error}</p>}

      <div className="filters-dropdown">
        <button
          className="dropdown-toggle"
          onClick={() => setFiltersVisible(!filtersVisible)}
        >
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </button>

        {filtersVisible && (
          <div className="filters-content">
            <div className="filter-group">
              <select
                id="locationSelect"
                onChange={handleFilterChange(setSelectedLocationId)}
                value={selectedLocationId}
              >
                <option value="Lall">All Locations</option>
                {locationIds.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="categorySelect"
                onChange={handleFilterChange(setSelectedCategory)}
                value={selectedCategory}
              >
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="subCategorySelect"
                onChange={handleFilterChange(setSelectedSubCategory)}
                value={selectedSubCategory}
              >
                <option value="">All Sub-Categories</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="criteriaSelect"
                onChange={handleFilterChange(setSelectedCriteria)}
                value={selectedCriteria}
              >
                <option value="">All Criteria</option>
                {criteriaList.map((criteria) => (
                  <option key={criteria} value={criteria}>
                    {criteria}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <select
                id="seasonSelect"
                onChange={handleFilterChange(setSelectedSeason)}
                value={selectedSeason}
              >
                <option value="">All Seasons</option>
                {seasons.map((season) => (
                  <option key={season} value={season}>
                    {season}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <p>Loading data...</p>
      ) : (
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={350}
        />
      )}

      <div className="summary-table">
        <h3>Summary Table</h3>
        <table>
          <thead>
            <tr>
              <th>Particulate Matter</th>
              <th>Max Value</th>
              <th>Min Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PM1</td>
              <td>{maxPm1 !== null ? maxPm1 : "N/A"}</td>
              <td>{minPm1 !== null ? minPm1 : "N/A"}</td>
            </tr>
            <tr>
              <td>PM2.5</td>
              <td>{maxPm2_5 !== null ? maxPm2_5 : "N/A"}</td>
              <td>{minPm2_5 !== null ? minPm2_5 : "N/A"}</td>
            </tr>
            <tr>
              <td>PM10</td>
              <td>{maxPm10 !== null ? maxPm10 : "N/A"}</td>
              <td>{minPm10 !== null ? minPm10 : "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GrimmsData;

*/